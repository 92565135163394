import { NgModule } from '@angular/core';
import { AccountService } from './account.service';
import { ApiService } from './api.service';
import { BoxService } from './box.service';
import { CreditService } from './credit.service';
import { ErrorService } from './error.service';
import { MapService } from './map.service';
import { SettingsService } from './settings.service';
import { UserService } from './user.service';
import { PackageService } from './package.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './auth-interceptor.service';
import { AuthServiceOidc } from './auth-service.component';
import { AdminRouteGuard } from './admin-route-guard';
@NgModule({
	declarations: [],
	imports: [
		// AccountService,
		// ApiService,
		// AuthService,
		// BoxService,
		// CreditService,
		// ErrorService,
		// GraphService,
		// MapService,
		// PackageService,
		// SettingsService,
		// UserService,
	],
	exports: [
		// AccountService,
		// ApiService,
		// AuthService,
		// BoxService,
		// CreditService,
		// ErrorService,
		// GraphService,
		// MapService,
		// PackageService,
		// SettingsService,
		// UserService,
	],
	providers: [
		// AccountService,
		// ApiService,
		// AuthService,
		// BoxService,
		// CreditService,
		// ErrorService,
		// GraphService,
		// MapService,
		// PackageService,
		// SettingsService,
		// UserService,
		// AuthServiceOidc,
        // //AccountService,
        // // ProjectService,
        AdminRouteGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
	],
})
export class ServicesModule { }


