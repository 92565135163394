import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule, Storage } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserService } from './services/user.service';
import { ApiService } from './services/api.service';
import { PackageService } from './services/package.service';
import { BoxService } from './services/box.service';
import { CreditService } from './services/credit.service';
import { MapService } from './services/map.service';
import { AccountService } from './services/account.service';
import { ErrorService } from './services/error.service';
import { SettingsService } from './services/settings.service';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from './components/components.module';
import { CustomErrorHandler } from './custom-error-handler';

/* Feature Modules */
import { LabAppSettingsModule } from './lab-app-settings/lab-app-settings.module';
import { LabMapModule } from './lab-maps/lab-map.module';


import { StoreModule } from '@ngrx/store';
// import { reducer } from './state/root.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { ProductData } from './products/product-data';
import { EffectsModule } from '@ngrx/effects';
import { KtInMemDataService } from './in-memory-db-service/kt-in-mem-data.service';
import { ServerErrorInterceptor } from './server-error-interceptor';
import { StorageService } from './services/storage.service';
import { LabAccountModule } from './lab-account/lab-account.module';
import { AdminModule } from './admin/admin.module';
import { AuthServiceOidc } from './services/auth-service.component';
import { LabBoxModule } from './lab-boxes/lab-box.module';

// import { environment } from '../environments/environment'; // Angular CLI environemnt

// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

export function provideSettings(storage: Storage) {
  /**
   * The Settings provider takes a set of default settings for your app.
   *
   * You can add new settings options at any time. Once the settings are saved,
   * these values will not overwrite the saved values (this can be done manually if desired).
   */
  return new SettingsService(storage, {
    option1: true,
    option2: 'Ionitron J. Framework',
    option3: '3',
    option4: 'Hello'
  });
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

    // HttpClientInMemoryWebApiModule.forRoot(
    //   KtInMemDataService, { passThruUnknownUrl: true, dataEncapsulation: false }
    // ),
    LabAppSettingsModule,
    LabMapModule,
    LabBoxModule,

    LabAccountModule,
    AdminModule,

    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    // // StoreModule.forFeature('products', reducer)
    // StoreModule.forRoot({products: reducer}),
    // StoreDevtoolsModule.instrument({
    //   name: 'LetABot Demo Store',
    //   maxAge: 25, // Retains last 25 states
    //   logOnly: environment.production, // Restrict extension to log-only mode
    // }),


    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      name: 'LetABot Demo App DevTools',
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([])




  ],
  declarations: [AppComponent],
  providers: [
    InAppBrowser,
    SplashScreen,
    StatusBar,
    ApiService,
    ErrorService,
    UserService,
    { provide: SettingsService, useFactory: provideSettings, deps: [Storage] },

    // TODO Franz
    // https://forum.ionicframework.com/t/ionicerrorhandler-in-ionic-v4/135286/2

    // // // Keep this to enable Ionic's runtime error handling during development
    // // { provide: ErrorHandler, useClass: IonicErrorHandler },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },

    PackageService,
    BoxService,
    CreditService,
    MapService,
    AccountService,
    AuthServiceOidc,
    // KesaktenService,
    StorageService,





  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
