export class KtBox {

    public name: string;
    public id: string;
    public mapId: string;
    public properties: string;


    constructor(dataObject?: any) {
        if (dataObject != null) {
            if (dataObject.Name != null) { this.name = dataObject.Name; }
            if (dataObject.Id != null) { this.id = dataObject.Id; }
            if (dataObject.MapId != null) { this.mapId = dataObject.MapId; }
            if (dataObject.Properties != null) { this.properties = dataObject.Properties; }
        }
    }

    public getEditObject() {
        let itemTmp = {
            "Name": this.name,
        }
        return itemTmp;
    }

}

