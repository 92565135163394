import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromLabMap from './../../state';
import * as labMapActions from './../../state/lab-map.actions';
import { LabMap } from '../../lab-map';

import * as fromLabAppSettings from '../../../lab-app-settings/state';
import { LabAppSettings } from '../../../lab-app-settings/lab-app-settings';


@Component({
  templateUrl: './lab-map-shell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabMapShellComponent implements OnInit, OnDestroy {
  displayId$: Observable<boolean>;
  selectedLabMap$: Observable<LabMap>;
  labMaps$: Observable<LabMap[]>;
  errorMessage$: Observable<string>;

  labAppSettings: LabAppSettings = null;
  subscriptions: Subscription[] = [];

  loading: boolean = false;

  private _refresher: any = null;

  constructor(
    private store: Store<fromLabMap.State>,
    private changeDetectorRef: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    this.labMaps$ = this.store.pipe(select(fromLabMap.getLabMaps));
    this.errorMessage$ = this.store.pipe(select(fromLabMap.getError));
    this.selectedLabMap$ = this.store.pipe(select(fromLabMap.getCurrentLabMap));
    this.displayId$ = this.store.pipe(select(fromLabMap.getShowLabMapId));

    this.subscriptions.push(this.store.pipe(
      select(fromLabAppSettings.getLabAppSettings)
    ).subscribe(labAppSettings => {
      this.labAppSettings = labAppSettings;
      this.loadMaps();
    }));


    this.subscriptions.push(
      this.labMaps$.subscribe(() => {
        this.setLoading(false);

      })
    );

    this.subscriptions.push(
      this.errorMessage$.subscribe(() => {
        this.setLoading(false);
      })
    );



  }


  ionViewWillEnter() {
    this.loadMaps();
  }




  // Iterate and unsubscribe from each subscription
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe()
    );
  }


  checkChanged(value: boolean): void {
    this.store.dispatch(new labMapActions.ToggleLabMapCode(value));
  }

  newLabMap(): void {
    this.store.dispatch(new labMapActions.InitializeCurrentLabMap());
  }

  labMapSelected(labMap: LabMap): void {
    this.store.dispatch(new labMapActions.SetCurrentLabMap(labMap));

    this.labAppSettings.currentMapId = labMap.id;
    this.store.dispatch(new fromLabAppSettings.SetLabAppSettings(this.labAppSettings));
  }

  deleteLabMap(labMap: LabMap): void {
    this.store.dispatch(new labMapActions.DeleteLabMap(labMap.id));
  }

  clearLabMap(): void {
    this.store.dispatch(new labMapActions.ClearCurrentLabMap());
  }
  saveLabMap(labMap: LabMap): void {
    this.store.dispatch(new labMapActions.CreateLabMap(labMap));
  }

  updateLabMap(labMap: LabMap): void {
    this.store.dispatch(new labMapActions.UpdateLabMap(labMap));
  }

  refreshList() {
    this.loadMaps();
  }

  doRefresh(event) {
    this._refresher = event.target;
    this.refreshList();
  }



  loadMaps() {
    this.setLoading(true);
    this.store.dispatch(new labMapActions.Load());
  }

  private setLoading(loading: boolean) {
    let viewUpdate = this.loading != loading;
    this.loading = loading;

    if (loading == false) {
      // set the refresher to completed
      if (this._refresher != null) {
        this._refresher.complete();
        this._refresher = null;
        viewUpdate = true;
      }
    }

    if (viewUpdate) {
      this.changeDetectorRef.markForCheck();
    }

  }

}
