import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LabMap } from '../../lab-map';

@Component({
  selector: 'lab-map-list',
  templateUrl: './lab-map-list.component.html',
  styleUrls: ['./lab-map-list.component.scss']
})
export class LabMapListComponent {
  pageTitle = 'LabMaps';

  @Input() errorMessage: string;
  @Input() labMaps: LabMap[];
  @Input() displayId: boolean;
  @Input() selectedLabMap: LabMap;
  @Output() checked = new EventEmitter<boolean>();
  @Output() initializeNewLabMap = new EventEmitter<void>();
  @Output() selected = new EventEmitter<LabMap>();
  @Output() refresh = new EventEmitter<void>();

  checkChanged(value: boolean): void {
    this.checked.emit(value);
  }

  newLabMap(): void {
    this.initializeNewLabMap.emit();
  }

  labMapSelected(labMap: LabMap): void {
    this.selected.emit(labMap);
  }

  refreshMaps(): void {
    this.refresh.emit();
  }

}
