import { Injectable } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { ErrorService } from './services/error.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

    constructor(
        public errorService: ErrorService
    ) {
        // Call parent constructor (with no parameters).
        // super();
    }

    handleError(err: any): void {

        console.log(`Franz CustomErrorHandler:handleError`);
        //  // Call parent handleError method.
        // super.handleError(err);

        // Wrap custom code in empty try/catch.
        try {
            this.errorService.log(err);
        } catch (e) { }
    }
}