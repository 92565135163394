import { LabBox } from '../lab-box';

/* NgRx */
import { Action } from '@ngrx/store';

export enum LabBoxActionTypes {
  ToggleLabBoxCode = '[LabBox] Toggle Box Code',
  SetCurrentLabBox = '[LabBox] Set Current Box',
  ClearCurrentLabBox = '[LabBox] Clear Current Box',
  InitializeCurrentLabBox = '[LabBox] Initialize Current Box',
  Load = '[LabBox] Load',
  LoadSuccess = '[LabBox] Load Success',
  LoadFail = '[LabBox] Load Fail',
  UpdateLabBox = '[LabBox] Update Box',
  UpdateLabBoxSuccess = '[LabBox] Update Box Success',
  UpdateLabBoxFail = '[LabBox] Update Box Fail',
  UpdateLabBoxConnectToFunction = '[LabBox] Update Box ConnectToFunction',
  UpdateLabBoxConnectToFunctionSuccess = '[LabBox] Update Box ConnectToFunction Success',
  UpdateLabBoxConnectToFunctionFail = '[LabBox] Update Box ConnectToFunction Fail',
  SendLabBoxMessage = '[LabBox] Send LabBox Message',
  SendLabBoxMessageSuccess = '[LabBox] Send LabBox Message Success',
  SendLabBoxMessageFail = '[LabBox] Send LabBox Message Fail',
  CreateLabBox = '[LabBox] Create Box',
  CreateLabBoxSuccess = '[LabBox] Create Box Success',
  CreateLabBoxFail = '[LabBox] Create Box Fail',
  DeleteLabBox = '[LabBox] Delete Box',
  DeleteLabBoxSuccess = '[LabBox] Delete Box Success',
  DeleteLabBoxFail = '[LabBox] Delete Box Fail'
}

// Action Creators
export class ToggleLabBoxCode implements Action {
  readonly type = LabBoxActionTypes.ToggleLabBoxCode;

  constructor(public payload: boolean) { }
}

export class SetCurrentLabBox implements Action {
  readonly type = LabBoxActionTypes.SetCurrentLabBox;

  constructor(public payload: LabBox) { }
}

export class ClearCurrentLabBox implements Action {
  readonly type = LabBoxActionTypes.ClearCurrentLabBox;
}

export class InitializeCurrentLabBox implements Action {
  readonly type = LabBoxActionTypes.InitializeCurrentLabBox;
}

export class Load implements Action {
  readonly type = LabBoxActionTypes.Load;
}

export class LoadSuccess implements Action {
  readonly type = LabBoxActionTypes.LoadSuccess;

  constructor(public payload: LabBox[]) { }
}

export class LoadFail implements Action {
  readonly type = LabBoxActionTypes.LoadFail;

  constructor(public payload: string) { }
}

export class UpdateLabBox implements Action {
  readonly type = LabBoxActionTypes.UpdateLabBox;

  constructor(public payload: LabBox) { }
}

export class UpdateLabBoxSuccess implements Action {
  readonly type = LabBoxActionTypes.UpdateLabBoxSuccess;

  constructor(public payload: LabBox) { }
}

export class UpdateLabBoxFail implements Action {
  readonly type = LabBoxActionTypes.UpdateLabBoxFail;

  constructor(public payload: string) { }
}



export class UpdateLabBoxConnectToFunction implements Action {
  readonly type = LabBoxActionTypes.UpdateLabBoxConnectToFunction;

  constructor(public payload: LabBox) { }
}

export class UpdateLabBoxConnectToFunctionSuccess implements Action {
  readonly type = LabBoxActionTypes.UpdateLabBoxConnectToFunctionSuccess;

  constructor(public payload: LabBox) { }
}

export class UpdateLabBoxConnectToFunctionFail implements Action {
  readonly type = LabBoxActionTypes.UpdateLabBoxConnectToFunctionFail;

  constructor(public payload: string) { }
}



export class SendLabBoxMessage implements Action {
  readonly type = LabBoxActionTypes.SendLabBoxMessage;

  constructor(public payload: LabBox) { }
}

export class SendLabBoxMessageSuccess implements Action {
  readonly type = LabBoxActionTypes.SendLabBoxMessageSuccess;

  constructor(public payload: LabBox) { }
}

export class SendLabBoxMessageFail implements Action {
  readonly type = LabBoxActionTypes.SendLabBoxMessageFail;

  constructor(public payload: string) { }
}


export class CreateLabBox implements Action {
  readonly type = LabBoxActionTypes.CreateLabBox;

  constructor(public payload: LabBox) { }
}

export class CreateLabBoxSuccess implements Action {
  readonly type = LabBoxActionTypes.CreateLabBoxSuccess;

  constructor(public payload: LabBox) { }
}

export class CreateLabBoxFail implements Action {
  readonly type = LabBoxActionTypes.CreateLabBoxFail;

  constructor(public payload: string) { }
}

export class DeleteLabBox implements Action {
  readonly type = LabBoxActionTypes.DeleteLabBox;

  constructor(public payload: string) { }
}

export class DeleteLabBoxSuccess implements Action {
  readonly type = LabBoxActionTypes.DeleteLabBoxSuccess;

  constructor(public payload: string) { }
}

export class DeleteLabBoxFail implements Action {
  readonly type = LabBoxActionTypes.DeleteLabBoxFail;

  constructor(public payload: string) { }
}

// Union the valid types
export type LabBoxActions = ToggleLabBoxCode
  | SetCurrentLabBox
  | ClearCurrentLabBox
  | InitializeCurrentLabBox
  | Load
  | LoadSuccess
  | LoadFail
  | UpdateLabBox
  | UpdateLabBoxSuccess
  | UpdateLabBoxFail
  | UpdateLabBoxConnectToFunction
  | UpdateLabBoxConnectToFunctionSuccess
  | UpdateLabBoxConnectToFunctionFail
  | SendLabBoxMessage
  | SendLabBoxMessageSuccess
  | SendLabBoxMessageFail  
  | CreateLabBox
  | CreateLabBoxSuccess
  | CreateLabBoxFail
  | DeleteLabBox
  | DeleteLabBoxSuccess
  | DeleteLabBoxFail;

