import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { KtAccount } from '../models/ktaccount';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AccountService {


  constructor(
    public api: ApiService
  ) {
    console.log('Hello AccountServiceProvider Provider');
  }


  getAccount(): Promise<KtAccount> {

    let url = 'v1.0/me';

    return new Promise((resolve, reject) => {
      let params = null;
      this.api.get(url, params)
        .subscribe((data: any) => {
          console.log(`FRANZ: data: const me = ${JSON.stringify(data)}`);
          var dataObject = data as Object;
          let item: KtAccount = new KtAccount(dataObject);
          resolve(item);
        }, (error) => {
          this.handleError(error);
        });
    });

  }

  // getAccountO(): Observable<KtAccount> {

  //   let url = 'v1.0/me';

  //   let params = null;
  //   this.api.get(url, params)
  //     .subscribe((data: any) => {
  //       console.log(`FRANZ: data: const me = ${JSON.stringify(data)}`);
  //       var dataObject = data as Object;
  //       let item: KtAccount = new KtAccount(dataObject);
  //       resolve(item);
  //     }, (error) => {
  //       this.handleError(error);
  //     });

  // }

  // getAccountO3(): Observable<any> {
  //   let url = 'v1.0/me';
  //   let params = null;
  //   return this.api.get(url, params)
  //     .pipe(
  //       tap(data => console.log('Franz: getAccountO2: All: ' + JSON.stringify(data))),
  //       catchError(this.handleError)
  //     );
  // }



  // getAccountO2(): Observable<any> {

  //   return this.getAccountO3()

  //   // let url = 'v1.0/me';
  //   // let params = null;
  //   // return this.api.get(url, params)
  //   //   .pipe(
  //   //     tap(data => console.log('Franz: getAccountO2: All: ' + JSON.stringify(data))),
  //   //     catchError(this.handleError)
  //   //   )
  //     .pipe(
  //       map((response: Response) => {
  //         console.log(`FRANZ: getAccountO2: const me = ${JSON.stringify(response)}`);
  //         var dataObject = response as Object;
  //         let item: KtAccount = new KtAccount(dataObject);
  //         return item;
  //       },
  //         catchError((response: Response) => throwError(response))
  //       )
  //     );
  // }





  getAccountO2(): Observable<any> {

    // return this.getAccountO3()

    let url = 'v1.0/me';
    let params = null;
    return this.api.get(url, params)
      .pipe(
        // tap(data => console.log('Franz: getAccountO2: All: ' + JSON.stringify(data))),
        map((response) => {
          console.log(`FRANZ: getAccountO2: const me = ${JSON.stringify(response)}`);
          var dataObject = response as Object;
          let item: KtAccount = new KtAccount(dataObject);
          return item;
        }),
        catchError((response: Response) => throwError(response))
      );
  }






  editAccount(account: KtAccount) {

    let url = 'v1.0/me';

    return new Promise((resolve, reject) => {
      let params = null;
      let editObject = account.getEditObject();
      let body = JSON.stringify(editObject);

      console.log(`FRANZ: body: ${JSON.stringify(body)}`);

      this.api.post(url, body, params)
        .subscribe((data: any) => {
          console.log(`FRANZ: data: ${JSON.stringify(data)}`);
          // var dataObject = data as Object;

          // let item: KtAccount = new KtAccount(dataObject);
          resolve({});
        }, (error) => {
          this.handleError(error);
        });
    });

  }



  newAccount() {

  }




  // private handleError(error: HttpErrorResponse): Promise<any> {
  //   console.error(`${this.constructor.name}: An error occurred`, error);
  //   return Promise.reject(error.message || error);
  // }



  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else
      if (typeof err === 'string') {
        errorMessage = err;
      }
      else {
        // // The backend returned an unsuccessful response code.
        // // The response body may contain clues as to what went wrong,
        // errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
        errorMessage = `An error occurred ${err} / ${JSON.stringify(err)}`;
      }
    // console.error(err);
    return throwError(err);
    // return throwError(errorMessage);
  }

}
