import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Constants } from '../../constants';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

// TODO verbessern:
// https://medium.com/better-programming/a-generic-http-service-approach-for-angular-applications-a7bd8ff6a068


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // url: string = 'https://example.com/api/v1';
  // url: string = 'http://localhost:7071/api';
  // url: string = 'https://kesakten.azurewebsites.net/api';
  // https://kesakten.azurewebsites.net/api/v1.0/me/boxes?code=k2mFk/xiIOR7RoryjULacQmO/jnnYBvpayUNswnUYVF2lkMFNSrrkA==
  // https://kesakten.azurewebsites.net/api/v1.0/me/boxes?code=k2mFk/xiIOR7RoryjULacQmO/jnnYBvpayUNswnUYVF2lkMFNSrrkA==
  // https://kesakten.azurewebsites.net/api/v1.0/audit?code=VcQfcjjVtOM7xdEmDTfHGZ1b/shIi3G1Iz1azo1dBoyReitfQzXUdw==

  // default host key
  // https://kesakten.azurewebsites.net/api/v1.0/audit?code=nXJCneTVk0vGcN8IbpU47t6r0KvTHrfa44jvLSmosIbGVNuacCWERA==
  // https://kesakten.azurewebsites.net/api/v1.0/me/boxes?code=nXJCneTVk0vGcN8IbpU47t6r0KvTHrfa44jvLSmosIbGVNuacCWERA==
  // https://kesakten.azurewebsites.net/api/v1.0/audit?code=nXJCneTVk0vGcN8IbpU47t6r0KvTHrfa44jvLSmosIbGVNuacCWERA==


  // https://kesakten2.azurewebsites.net/api/v1.0/audit?code=LZx9r/WlKxLQovdhM6aQzbBs7JRiHSl2d93TpcxWaUBHuihenG7pLg==
  // https://kesakten2.azurewebsites.net/api/v1.0/me/boxes?code=LZx9r/WlKxLQovdhM6aQzbBs7JRiHSl2d93TpcxWaUBHuihenG7pLg==
  constructor(public http: HttpClient) {
  }

  get<T>(endpoint: string, params?: any, reqOpts?: any) {
    reqOpts = this.CreateReqOpts(reqOpts, params);
    return this.http.get<T>(this.createUrl(endpoint), reqOpts);
    // .pipe(
    //   // retry(3), // retry a failed request up to 3 times
    //   catchError(this.handleError) // then handle the error
    // );
  }



  // get2<T>(endpoint: string, params?: any, reqOpts?: any): Observable<T> {
  //   reqOpts = this.CreateReqOpts(reqOpts, params);
  //   return this.http.get(this.createUrl(endpoint), reqOpts) as any;
  // }



  getWithUrl(endpoint: string, params?: any, reqOpts?: any) {
    reqOpts = this.CreateReqOpts(reqOpts, params);
    return this.http.get(endpoint, reqOpts);
      // .pipe(
    //   // retry(3), // retry a failed request up to 3 times
    //   catchError(this.handleError) // then handle the error
    // );
  }

  post<T>(endpoint: string, body: any, reqOpts?: any) {
    reqOpts = this.CreateReqOpts(reqOpts, null);

    return this.http.post<T>(this.createUrl(endpoint), body, reqOpts);
    // .pipe(
    //   // retry(3), // retry a failed request up to 3 times
    //   catchError(this.handleError) // then handle the error
    // );
  }

  put<T>(endpoint: string, body: any, reqOpts?: any) {
    reqOpts = this.CreateReqOpts(reqOpts, null);

    return this.http.put<T>(this.createUrl(endpoint), body, reqOpts);
    // .pipe(
    //   // retry(3), // retry a failed request up to 3 times
    //   catchError(this.handleError) // then handle the error
    // );
  }

  // delete(endpoint: string, reqOpts?: any) {
  //   reqOpts = this.CreateReqOpts(reqOpts, null);
  //   return this.http.delete(this.createUrl(endpoint), reqOpts);
  //   // .pipe(
  //   //   // retry(3), // retry a failed request up to 3 times
  //   //   catchError(this.handleError) // then handle the error
  //   // );
  // }


  delete<T>(endpoint: string, reqOpts?: any) {
    reqOpts = this.CreateReqOpts(reqOpts, null);
    return this.http.delete<T>(this.createUrl(endpoint), reqOpts);
    // .pipe(
    //   // retry(3), // retry a failed request up to 3 times
    //   catchError(this.handleError) // then handle the error
    // );
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    reqOpts = this.CreateReqOpts(reqOpts, null);
    return this.http.put(this.createUrl(endpoint), body, reqOpts);
      // .pipe(
      //   // retry(3), // retry a failed request up to 3 times
      //   catchError(this.handleError) // then handle the error
      // );
  }




  // private createUrl(endpoint: string): string {
  //   let url = "";
  //   if (Constants.ServiceLocalhost) {
  //     url = Constants.SERVICE_URL_TESTSYSTEM + '/' + endpoint;
  //   } else {
  //     url = Constants.SERVICE_URL + '/' + endpoint + '?code=' + Constants.SERVICE_URL_CODE;
  //     // return Constants.SERVICE_URL + '/' + endpoint;
  //   }
  //   console.log(`createUrl(${endpoint}) -> url: ${url}`);
  //   return url;
  // }


  private createUrl(endpoint: string): string {
    // let TEST_SERVER_ACCESS = true;
    if (Constants.SETTINGS_CONSTS.SERVICE_LOCALHOST && Constants.SETTINGS_CONSTS.TEST_SERVER_ACCESS){
    // if (TEST_SERVER_ACCESS){
      // return Constants.SERVICE_URL + '/' + endpoint;
      return Constants.SERVICE_URL + '/' + endpoint + '?code=' + Constants.SERVICE_URL_CODE;
    }

    const isLocal = /localhost/.test(window.location.href);
    if (isLocal) {
      return Constants.SERVICE_URL_TESTSYSTEM + '/' + endpoint;
    } else {
      return Constants.SERVICE_URL + '/' + endpoint + '?code=' + Constants.SERVICE_URL_CODE;
      //return Constants.AZURE_BASE_URL + '/' + endpoint;
    }
  }

  private CreateReqOpts(reqOpts: any, params: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params.set(k, params[k]);
      }
    }

    if (!reqOpts.headers) {
      reqOpts.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    return reqOpts;
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


}
