import { LabMap } from '../lab-map';

/* NgRx */
import { Action } from '@ngrx/store';

export enum LabMapActionTypes {
  ToggleLabMapCode = '[LabMap] Toggle LabMap Code',
  SetCurrentLabMap = '[LabMap] Set Current LabMap',
  ClearCurrentLabMap = '[LabMap] Clear Current LabMap',
  InitializeCurrentLabMap = '[LabMap] Initialize Current LabMap',
  Load = '[LabMap] Load',
  LoadSuccess = '[LabMap] Load Success',
  LoadFail = '[LabMap] Load Fail',
  UpdateLabMap = '[LabMap] Update LabMap',
  UpdateLabMapSuccess = '[LabMap] Update LabMap Success',
  UpdateLabMapFail = '[LabMap] Update LabMap Fail',
  CreateLabMap = '[LabMap] Create LabMap',
  CreateLabMapSuccess = '[LabMap] Create LabMap Success',
  CreateLabMapFail = '[LabMap] Create LabMap Fail',
  DeleteLabMap = '[LabMap] Delete LabMap',
  DeleteLabMapSuccess = '[LabMap] Delete LabMap Success',
  DeleteLabMapFail = '[LabMap] Delete LabMap Fail'
}

// Action Creators
export class ToggleLabMapCode implements Action {
  readonly type = LabMapActionTypes.ToggleLabMapCode;

  constructor(public payload: boolean) { }
}

export class SetCurrentLabMap implements Action {
  readonly type = LabMapActionTypes.SetCurrentLabMap;

  constructor(public payload: LabMap) { }
}

export class ClearCurrentLabMap implements Action {
  readonly type = LabMapActionTypes.ClearCurrentLabMap;
}

export class InitializeCurrentLabMap implements Action {
  readonly type = LabMapActionTypes.InitializeCurrentLabMap;
}

export class Load implements Action {
  readonly type = LabMapActionTypes.Load;
}

export class LoadSuccess implements Action {
  readonly type = LabMapActionTypes.LoadSuccess;

  constructor(public payload: LabMap[]) { }
}

export class LoadFail implements Action {
  readonly type = LabMapActionTypes.LoadFail;

  constructor(public payload: string) { }
}

export class UpdateLabMap implements Action {
  readonly type = LabMapActionTypes.UpdateLabMap;

  constructor(public payload: LabMap) { }
}

export class UpdateLabMapSuccess implements Action {
  readonly type = LabMapActionTypes.UpdateLabMapSuccess;

  constructor(public payload: LabMap) { }
}

export class UpdateLabMapFail implements Action {
  readonly type = LabMapActionTypes.UpdateLabMapFail;

  constructor(public payload: string) { }
}

export class CreateLabMap implements Action {
  readonly type = LabMapActionTypes.CreateLabMap;

  constructor(public payload: LabMap) { }
}

export class CreateLabMapSuccess implements Action {
  readonly type = LabMapActionTypes.CreateLabMapSuccess;

  constructor(public payload: LabMap) { }
}

export class CreateLabMapFail implements Action {
  readonly type = LabMapActionTypes.CreateLabMapFail;

  constructor(public payload: string) { }
}

export class DeleteLabMap implements Action {
  readonly type = LabMapActionTypes.DeleteLabMap;

  constructor(public payload: string) { }
}

export class DeleteLabMapSuccess implements Action {
  readonly type = LabMapActionTypes.DeleteLabMapSuccess;

  constructor(public payload: string) { }
}

export class DeleteLabMapFail implements Action {
  readonly type = LabMapActionTypes.DeleteLabMapFail;

  constructor(public payload: string) { }
}

// Union the valid types
export type LabMapActions = ToggleLabMapCode
  | SetCurrentLabMap
  | ClearCurrentLabMap
  | InitializeCurrentLabMap
  | Load
  | LoadSuccess
  | LoadFail
  | UpdateLabMap
  | UpdateLabMapSuccess
  | UpdateLabMapFail
  | CreateLabMap
  | CreateLabMapSuccess
  | CreateLabMapFail
  | DeleteLabMap
  | DeleteLabMapSuccess
  | DeleteLabMapFail;

