import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LabBox } from '../../lab-box';

@Component({
  selector: 'lab-box-list',
  templateUrl: './lab-box-list.component.html',
  styleUrls: ['./lab-box-list.component.scss']
})
export class LabBoxListComponent {
  pageTitle = 'LabBoxes';

  @Input() errorMessage: string;
  @Input() labBoxes: LabBox[];
  @Input() displayId: boolean;
  @Input() selectedLabBox: LabBox;
  @Output() checked = new EventEmitter<boolean>();
  @Output() initializeNewLabBox = new EventEmitter<void>();
  @Output() selected = new EventEmitter<LabBox>();

  checkChanged(value: boolean): void {
    this.checked.emit(value);
  }

  newLabBox(): void {
    this.initializeNewLabBox.emit();
  }

  labBoxSelected(labBox: LabBox): void {
    this.selected.emit(labBox);
  }

}
