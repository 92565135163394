import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, throwError } from 'rxjs';

import * as fromLabBox from './../../state';
import * as labBoxActions from './../../state/lab-box.actions';
import { LabBox } from '../../lab-box';

import { LabAppSettings } from '../../../lab-app-settings/lab-app-settings';

import * as fromLabAppSettings from '../../../lab-app-settings/state';
import { ModalController } from '@ionic/angular';
import { LabBoxConnectFunctionModalComponent } from '../../components/lab-box-connect-function-modal/lab-box-connect-function-modal.component';
import { OverlayEventDetail } from '@ionic/core';
import { LabBoxSendMessageModalComponent } from '../../components/lab-box-send-message-modal/lab-box-send-message-modal.component';


@Component({
  templateUrl: './lab-box-shell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabBoxShellComponent implements OnInit, OnDestroy {
  displayId$: Observable<boolean>;
  selectedLabBox$: Observable<LabBox>;
  labBoxes$: Observable<LabBox[]>;
  errorMessage$: Observable<string>;

  labAppSettings: LabAppSettings = null;
  subscriptions: Subscription[] = [];

  loading: boolean = false;

  private _refresher: any = null;

  private oldParameter: { mapId: string } = null;


  constructor(
    private store: Store<fromLabBox.State>,
    private changeDetectorRef: ChangeDetectorRef,
    private modalController: ModalController,

  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.store.pipe(
      select(fromLabAppSettings.getLabAppSettings)
    ).subscribe(labAppSettings => {
      this.labAppSettings = labAppSettings;
      var force = false;
      this.loadBoxes(force);
    }));

    this.labBoxes$ = this.store.pipe(select(fromLabBox.getLabBoxesForMap));
    this.errorMessage$ = this.store.pipe(select(fromLabBox.getError));
    this.selectedLabBox$ = this.store.pipe(select(fromLabBox.getCurrentLabBox));
    this.displayId$ = this.store.pipe(select(fromLabBox.getShowLabBoxId));

    this.subscriptions.push(
      this.labBoxes$.subscribe(() => {
        this.setLoading(false);

      })
    );
    this.subscriptions.push(
      this.errorMessage$.subscribe(() => {
        this.setLoading(false);
      })
    );
  }

  ionViewWillEnter() {
    const force = false;
    this.loadBoxes(force);
  }


  // Iterate and unsubscribe from each subscription
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe()
    );
  }


  checkChanged(value: boolean): void {
    this.store.dispatch(new labBoxActions.ToggleLabBoxCode(value));
  }

  newLabBox(): void {
    this.store.dispatch(new labBoxActions.InitializeCurrentLabBox());
  }

  labBoxSelected(labBox: LabBox): void {
    this.store.dispatch(new labBoxActions.SetCurrentLabBox(labBox));
    this.labAppSettings.currentBoxId = labBox.id;
    this.store.dispatch(new fromLabAppSettings.SetLabAppSettings(this.labAppSettings));
  }

  deleteLabBox(labBox: LabBox): void {
    this.store.dispatch(new labBoxActions.DeleteLabBox(labBox.id));
  }

  clearLabBox(): void {
    this.store.dispatch(new labBoxActions.ClearCurrentLabBox());
  }
  saveLabBox(labBox: LabBox): void {
    this.store.dispatch(new labBoxActions.CreateLabBox(labBox));
  }

  updateLabBox(labBox: LabBox): void {
    this.store.dispatch(new labBoxActions.UpdateLabBox(labBox));
  }


  updateLabBoxConnectToFunction(labBox: LabBox): void {
    this.store.dispatch(new labBoxActions.UpdateLabBoxConnectToFunction(labBox));
  }


  sendMessageLabBox(labBox: LabBox): void {
    this.store.dispatch(new labBoxActions.SendLabBoxMessage(labBox));
  }

  refreshList() {
    var force = true;
    this.loadBoxes(force);
  }

  doRefresh(event) {
    this._refresher = event.target;
    this.refreshList();
  }




  loadBoxes(force: boolean) {
    let parameter: { mapId: string } = {
      mapId: this.labAppSettings.currentMapId,
    }
    if (
      force ||
      this.oldParameter == null ||
      this.oldParameter.mapId != parameter.mapId
    ) {
      this.oldParameter = parameter;

      this.setLoading(true);

      this.store.dispatch(new labBoxActions.Load());
    }
  }

  private setLoading(loading: boolean) {
    let viewUpdate = this.loading != loading;
    this.loading = loading;

    if (loading == false) {
      // set the refresher to completed
      if (this._refresher != null) {
        this._refresher.complete();
        this._refresher = null;
        viewUpdate = true;
      }
    }

    if (viewUpdate) {
      this.changeDetectorRef.markForCheck();
    }

  }


  async connectBoxToFunction() {
    const modal = await this.modalController.create({
      component: LabBoxConnectFunctionModalComponent,
      componentProps: {
        'selectedLabBox$': this.selectedLabBox$,
        'errorMessage$': this.errorMessage$,
      }
    });


    modal.onDidDismiss().then((d: any) => this.handleModalDismiss_connectBoxToFunction(d));

    return await modal.present();
  }


  handleModalDismiss_connectBoxToFunction(detail: OverlayEventDetail) {
    if (detail !== null) {

      if (detail.role) {
        let labBox: LabBox | null = detail.data;

        switch (detail.role) {
          case LabBoxConnectFunctionModalComponent.ROLE_ADD:
            this.saveLabBox(labBox);
            break;
          case LabBoxConnectFunctionModalComponent.ROLE_UPDATE:
            this.updateLabBox(labBox);
            break;
          case LabBoxConnectFunctionModalComponent.ROLE_UPDATE_CONNECT_TO_FUNCTION:
            this.updateLabBoxConnectToFunction(labBox);
            break;


          default:
            throwError(`handleModalDismiss_connectBoxToFunction: Unknown role: ${detail.role}`);
            break;
        }

      }

    }
  }




  async sendMessage() {
    const modal = await this.modalController.create({
      component: LabBoxSendMessageModalComponent,
      componentProps: {
        'firstName': 'Douglas',
        'lastName': 'Adams',
        'middleInitial': 'N',

        'selectedLabBox$': this.selectedLabBox$,
        'errorMessage$': this.errorMessage$,
      }
    });


    modal.onDidDismiss().then((d: any) => this.handleModalDismiss_sendMessage(d));

    return await modal.present();
  }


  handleModalDismiss_sendMessage(detail: OverlayEventDetail) {
    if (detail !== null) {
      if (detail.role) {
        let labBox: LabBox | null = detail.data;
        this.sendMessageLabBox(labBox);
      }
    }
  }



}
