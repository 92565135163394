import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminRouteGuard } from '../services/admin-route-guard';
import { LabAdminComponent } from './lab-admin/lab-admin.component';

const routes: Routes = [
  {
    path: '',
    component: LabAdminComponent,
    canActivate: [AdminRouteGuard],
    // { path: 'admin/manage-permissions/:projectId', component: ManagePermissionsComponent,
    //     canActivate: [AdminRouteGuard] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule { }

//export const routedComponents = [LabAdminComponent];


