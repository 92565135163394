import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../shared/shared.module';


/* NgRx */
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/lab-app-settings.reducer';
import { LabAppSettingsEditComponent } from './components/lab-app-settings-edit/lab-app-settings-edit.component';
import { LabAppSettingsShellComponent } from './containers/lab-app-settings-shell/lab-app-settings-shell.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { LabAppSettingsEffects } from './state/lab-app-settings.effects';

const labAppSettingsRoutes: Routes = [
  // // { path: 'lab-app-settings-edit', component: LabAppSettingsEditComponent },
  // { path: 'lab-app-settings', component: LabAppSettingsShellComponent },
  { path: '', component: LabAppSettingsShellComponent },
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(labAppSettingsRoutes),
    StoreModule.forFeature('labAppSettings', reducer),
    EffectsModule.forFeature(
      [LabAppSettingsEffects]
    ),
    // StoreModule.forRoot('labAppSettings', reducer),
    // EffectsModule.forRoot(
    //   [LabAppSettingsEffects]
    // ),
    TranslateModule.forChild(),
    IonicModule,
  ],
  declarations: [
    LabAppSettingsShellComponent,
    LabAppSettingsEditComponent,
  ]
})
export class LabAppSettingsModule { }
