/* NgRx */
import { Action } from '@ngrx/store';
import { LabAppSettings } from '../lab-app-settings';

export enum LabAppSettingsActionTypes {
  // MaskUserName = '[LabAppSettings] Mask User Name',
  // UpdateLabAppSettings = '[LabAppSettings] Update LabAppSettings',
  // UpdateLabAppSettings_CurrentMap = '[LabAppSettings] Update LabAppSettings CurrentMap',
  // UpdateLabAppSettings_CurrentBox = '[LabAppSettings] Update LabAppSettings CurrentBox',
  // UpdateLabAppSettings_CurrentPackage = '[LabAppSettings] Update LabAppSettings CurrentPackage',

  // UpdateLabAppSettings_IsDemo = '[LabAppSettings] Update LabAppSettings IsDemo',




  Load = '[LabAppSettings] Load',
  LoadSuccess = '[LabAppSettings] Load Success',
  LoadFail = '[LabAppSettings] Load Fail',
  SetLabAppSettings = '[LabAppSettings] Set LabAppSettings',
  SetLabAppSettingsSuccess = '[LabAppSettings] Set LabAppSettings Success',
  SetLabAppSettingsFail = '[LabAppSettings] Set LabAppSettings Fail',
}

// export class MaskUserName implements Action {
//   readonly type = LabAppSettingsActionTypes.MaskUserName;

//   constructor(public payload: boolean) { }
// }


// export class UpdateLabAppSettings implements Action {
//   readonly type = LabAppSettingsActionTypes.UpdateLabAppSettings;

//   constructor(public payload: LabAppSettings) { }
// }


// export class UpdateLabAppSettings_CurrentMap implements Action {
//   readonly type = LabAppSettingsActionTypes.UpdateLabAppSettings_CurrentMap;

//   constructor(public payload: string) { }
// }
// export class UpdateLabAppSettings_CurrentBox implements Action {
//   readonly type = LabAppSettingsActionTypes.UpdateLabAppSettings_CurrentBox;

//   constructor(public payload: string) { }
// }
// export class UpdateLabAppSettings_CurrentPackage implements Action {
//   readonly type = LabAppSettingsActionTypes.UpdateLabAppSettings_CurrentPackage;

//   constructor(public payload: string) { }
// }

// export class UpdateLabAppSettings_IsDemo implements Action {
//   readonly type = LabAppSettingsActionTypes.UpdateLabAppSettings_IsDemo;

//   constructor(public payload: boolean) { }
// }




// export class Load implements Action {
//   readonly type = LabAppSettingsActionTypes.Load;
//   constructor(public payload: {}) { }
// }
export class LoadSuccess implements Action {
  readonly type = LabAppSettingsActionTypes.LoadSuccess;

  constructor(public payload: LabAppSettings) { }
}

export class LoadFail implements Action {
  readonly type = LabAppSettingsActionTypes.LoadFail;

  constructor(public payload: string) { }
}

// export class SetLabAppSettings implements Action {
//   readonly type = LabAppSettingsActionTypes.SetLabAppSettings;

//   constructor(public payload: LabAppSettings) { }
// }

export class SetLabAppSettingsSuccess implements Action {
  readonly type = LabAppSettingsActionTypes.SetLabAppSettingsSuccess;

  constructor(public payload: LabAppSettings) { }
}

export class SetLabAppSettingsFail implements Action {
  readonly type = LabAppSettingsActionTypes.SetLabAppSettingsFail;

  constructor(public payload: string) { }
}



// export type LabAppSettingsActions = //MaskUserName
// // | UpdateLabAppSettings
// // | UpdateLabAppSettings_CurrentMap
// // | UpdateLabAppSettings_CurrentBox
// // | UpdateLabAppSettings_CurrentPackage
// // | UpdateLabAppSettings_IsDemo
// Load
// | LoadSuccess
// | LoadFail
// | SetLabAppSettings
// | SetLabAppSettingsSuccess
// | SetLabAppSettingsFail
// ;
