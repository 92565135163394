/* NgRx */
import { createFeatureSelector, createSelector, Action } from '@ngrx/store';
import { LabAppSettingsState } from './lab-app-settings.reducer';
import { LoadSuccess, LoadFail, SetLabAppSettingsSuccess, SetLabAppSettingsFail, LabAppSettingsActionTypes } from './lab-app-settings.actions';
import { LabAppSettings } from '../lab-app-settings';


// Selector functions
const getLabAppSettingsFeatureState = createFeatureSelector<LabAppSettingsState>('labAppSettings');

export const getLabAppSettings = createSelector(
  getLabAppSettingsFeatureState,
  state => state.labAppSettings
);

// export const getMaskUserName = createSelector(
//   getLabAppSettingsFeatureState,
//   state => state.maskUserName
// );


export class Load implements Action {
  readonly type = LabAppSettingsActionTypes.Load;
  constructor(public payload: {}) { }
}

export class SetLabAppSettings implements Action {
  readonly type = LabAppSettingsActionTypes.SetLabAppSettings;

  constructor(public payload: LabAppSettings) { }
}

// export class SetLabAppSettingsSuccess implements Action {
//   readonly type = LabAppSettingsActionTypes.SetLabAppSettingsSuccess;

//   constructor(public payload: LabAppSettings) { }
// }

// export class SetLabAppSettingsFail implements Action {
//   readonly type = LabAppSettingsActionTypes.SetLabAppSettingsFail;

//   constructor(public payload: string) { }
// }




export type LabAppSettingsActions = //MaskUserName
// | UpdateLabAppSettings
// | UpdateLabAppSettings_CurrentMap
// | UpdateLabAppSettings_CurrentBox
// | UpdateLabAppSettings_CurrentPackage
// | UpdateLabAppSettings_IsDemo
Load
| LoadSuccess
| LoadFail
| SetLabAppSettings
| SetLabAppSettingsSuccess
| SetLabAppSettingsFail
;
