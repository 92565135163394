import { LabAppSettings } from './app/lab-app-settings/lab-app-settings';

// declare var SETTINGS_CONSTS: any;


// declare global {
//   interface Window { my: any; }
// }

/**
 */
export enum PackageStatus {

  UNKNOWN = 0,
  SENDING = 4,
  ROUTING = 5,
  DELIVERED = 6,
  UNDELIVERABLE = 7,
}



/**
 * All constants are hold in this class
 */
export class Constants {


  // public static ServiceLocalhost = SETTINGS_CONSTS && SETTINGS_CONSTS.SERVICE_LOCALHOST || false;

  // localhost but service online
  public static SETTINGS_CONSTS = {
    "SERVICE_LOCALHOST": true,
    'TEST_SERVER_ACCESS': true,
  };


  // cloud
  // public static SETTINGS_CONSTS = {
  //   "SERVICE_LOCALHOST": false,
  //   'TEST_SERVER_ACCESS': false,
  // };

  // public static SERVICE_LOCALHOST

    // // The application title
    // public static APP_TITLE: string = "Kesakten";

    // // massage, which is shown on startup, if it is empty, no message would be shown.
    // public static APP_START_MESSAGE: string = "";


    // // Prefix for the name of entries, which are stored in local storage 
    // public static STORAGE_PREFIX = "uc.App.Kesakten.";

    // The application version
    public static CLIENT_VERSION: string = "v1.0.0";

    // Web Service URL for produvtive system
    public static SERVICE_URL: string = "/api";
    // public static SERVICE_URL: string = "https://kesakten.azurewebsites.net/api";
    // // public static SERVICE_URL: string = "https://kesakten2.azurewebsites.net/api";
    
    public static SERVICE_URL_CODE: string = "nXJCneTVk0vGcN8IbpU47t6r0KvTHrfa44jvLSmosIbGVNuacCWERA==";
    // public static SERVICE_URL_CODE: string = "LZx9r/WlKxLQovdhM6aQzbBs7JRiHSl2d93TpcxWaUBHuihenG7pLg==";

    // Web Service URL for staging/test system        
    public static SERVICE_URL_TESTSYSTEM: string = "http://localhost:7071/api";

  

    // public static SPLASH_SCREEN_DELAY = 3000;

    // // Default domain for user authentication

    // // Value for ServiceIdentifier, which is added on each Service Call
    // public static SERVICE_IDENTIFIER = "UC_KESAKTEN_SERVICE";

    // // Profix for error messages in the logger  
    // public static APP_NAME_ERROR_PREFIX: string = '[' + Constants.APP_TITLE + ' Error] ';



    
    public static DEMO_MAPID: string = "dc9bb248-7576-4121-bb53-48d36d76f479";
    public static DEMO_BOXID: string = "45981d96-032e-4fd7-ba5e-90bc798810c4";
    public static SIMULATE_MAP_PACKAGEID: string = "11111111-bc1e-455d-9c20-9a934433e8c1";


    // Authorization OIDC:

    public static clientRoot = Constants.SETTINGS_CONSTS.SERVICE_LOCALHOST ? 'http://localhost:4200/' : 'https://game.letabot.com/';
    // public static clientRoot = 'https://game.letabot.com/';
    // public static clientRoot = 'http://localhost:4200/';
    
    // public static apiRoot = 'https://letabottest1.azurewebsites.net/api/';
    public static apiRoot = 'https://kesakten.azurewebsites.net/api/';
    public static stsAuthority = "https://letabottest1.b2clogin.com/letabottest1.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1_SignupSignin";
    public static clientId = 'b6669d6e-271c-4e1b-91bd-6485efd4b172'; // letabottest1 - SPA: AppId
    public static  scope = "openid https://letabottest1.onmicrosoft.com/API/access https://letabottest1.onmicrosoft.com/API/user_impersonation";




// from https://medium.com/the-new-control-plane/using-proof-key-for-code-exchange-pkce-in-azure-ad-b2c-9203fbc148fd
    // https://my-tenant.b2clogin.com/my-tenant.onmicrosoft.com/oauth2/v2.0/authorize
    // ?response_type=code
    // &client_id=25bd…9521
    // &scope=openid
    // &redirect_uri=https://jwt.io
    // &prompt=login
    // &code_challenge=_r67lcj4MoDNBAkhxS7ke_YKhKCBAiM0SgzNCagbCxo
    // &code_challenge_method=S256
    // &p=B2C_1_SUSI_V2

    // https://letabottest1.b2clogin.com/letabottest1.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1_signupsignin 400 (Bad Request)

    //https://letabottest1.b2clogin.com/letabottest1.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1_signupsignin


    public static DEFAULT_LAB_APP_SETTINGS: LabAppSettings =
    {
      currentMapId: "",
      currentBoxId: "",
      currentPackageId: "",
      isDemo: false,
      darkMode: false,
    };
    public static GUID_EMPTY = "00000000-0000-0000-0000-000000000000";

    
  }