import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { LabMapShellComponent } from './containers/lab-map-shell/lab-map-shell.component';
import { LabMapListComponent } from './components/lab-map-list/lab-map-list.component';
import { LabMapEditComponent } from './components/lab-map-edit/lab-map-edit.component';

/* NgRx */
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/lab-map.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LabMapEffects } from './state/lab-map.effects';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

const labMapRoutes: Routes = [
  { path: '', component: LabMapShellComponent }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(labMapRoutes),
    StoreModule.forFeature('labMaps', reducer),
    EffectsModule.forFeature(
      [ LabMapEffects ]
    ),
    TranslateModule.forChild(),
    IonicModule,
  ],
  declarations: [
    LabMapShellComponent,
    LabMapListComponent,
    LabMapEditComponent
  ]
})
export class LabMapModule { }
