import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LabMap } from '../../lab-map';
import { GenericValidator } from '../../../shared/generic-validator';
import { NumberValidators } from '../../../shared/number.validator';

@Component({
  selector: 'lab-map-edit',
  templateUrl: './lab-map-edit.component.html',
  styleUrls: ['./lab-map-edit.component.scss']
})
export class LabMapEditComponent implements OnInit, OnChanges, OnDestroy {
  pageTitle = 'LabMap Edit';
  @Input() errorMessage: string;
  @Input() selectedLabMap: LabMap;
  @Output() create = new EventEmitter<LabMap>();
  @Output() update = new EventEmitter<LabMap>();
  @Output() delete = new EventEmitter<LabMap>();
  @Output() clearCurrent = new EventEmitter<void>();

  componentActive = true;
  labMapForm: FormGroup;

  labMap: LabMap | null;

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  constructor(private fb: FormBuilder) {
    // Defines all of the validation messages for the form.
    // These could instead be retrieved from a file or database.
    this.validationMessages = {
      // name: {
      //   required: 'LabMap name is required.',
      //   minlength: 'LabMap name must be at least three characters.',
      //   maxlength: 'LabMap name cannot exceed 50 characters.'
      // },
      // mapId: {
      //   required: 'LabMap code is required.'
      // },
      // starRating: {
      //   range: 'Rate the labMap between 1 (lowest) and 5 (highest).'
      // }
    };

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    // Define the form group
    this.labMapForm = this.fb.group({
      id: {value: '', disabled: true},
      name: '',
      version: {value: 0, disabled: true},
      level: {value: 0, disabled: true},
      imageURL: '',
    });

    // Watch for value changes
    this.labMapForm.valueChanges.subscribe(
      value => this.displayMessage = this.genericValidator.processMessages(this.labMapForm)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {

    // patch form with value from the store
    if (changes.selectedLabMap) {
      const labMap: any = changes.selectedLabMap.currentValue as LabMap;

      this.displayLabMap(labMap);
    }
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  // Also validate on blur
  // Helpful if the user tabs through required fields
  blur(): void {
    this.displayMessage = this.genericValidator.processMessages(this.labMapForm);
  }

  displayLabMap(labMap: LabMap | null): void {
    // Set the local labMap property
    this.labMap = labMap;

    if (this.labMap && this.labMapForm) {
      // Reset the form back to pristine
      this.labMapForm.reset();

      // Display the appropriate page title
      if (this.labMap.id === "0") {
        this.pageTitle = 'Add LabMap';
      } else {
        this.pageTitle = `Edit LabMap`;
      }

      // Update the data on the form
      this.labMapForm.patchValue({
        id: this.labMap.id,
        name: this.labMap.name,
        version: this.labMap.version,
        level: this.labMap.level,
        imageURL: this.labMap.imageURL,
      });
    }
  }

  cancelEdit(): void {
    // Redisplay the currently selected labMap
    // replacing any edits made
    this.displayLabMap(this.labMap);
  }

  // deleteLabMap(): void {
  //   if (this.labMap && this.labMap.id) {
  //     if (confirm(`Really delete the labMap: ${this.labMap.name}?`)) {
  //       this.delete.emit(this.labMap);
  //     }
  //   } else {
  //     // No need to delete, it was never saved
  //     this.clearCurrent.emit();
  //   }
  // }

  saveLabMap(): void {
    if (this.labMapForm.valid) {
      if (this.labMapForm.dirty) {
        // Copy over all of the original labMap properties
        // Then copy over the values from the form
        // This ensures values not on the form, such as the Id, are retained
        const p = { ...this.labMap, ...this.labMapForm.value };

        if (p.id === 0) {
          this.create.emit(p);
        } else {
          this.update.emit(p);
        }
      }
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

}
