import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../state/app.state';
import * as fromLabAccount from './lab-account.reducer';

// Extends the app state to include the labAccount feature.
// This is required because labAccounts are lazy loaded.
// So the reference to LabAccountState cannot be added to app.state.ts directly.
export interface State extends fromRoot.State {
    labAccount: fromLabAccount.LabAccountState;
}

// Selector functions
const getLabAccountFeatureState = createFeatureSelector<fromLabAccount.LabAccountState>('labAccount');

// export const getShowLabAccountId = createSelector(
//     getLabAccountFeatureState,
//     state => state.showLabAccountId
// );

// export const getCurrentLabAccountId = createSelector(
//     getLabAccountFeatureState,
//     state => state.currentLabAccountId
// );

// export const getCurrentLabAccount = createSelector(
//     getLabAccountFeatureState,
//     getCurrentLabAccountId,
//     (state, currentLabAccountId) => {
//         if (currentLabAccountId === "0") {
//             return {
//                 userId: "0",
//                 domain: '',
//                 identity: '',
//                 displayName: '',
//                 email: '',
//                 userType: 3,
//                 level: 1,
//                 homeMapId: '',
//                 settings: '',
//                 imageURL: '',
//             };
//         } else {
//             return currentLabAccountId ? state.labAccounts.find(p => p.userId === currentLabAccountId) : null;
//         }
//     }
// );

export const getLabAccount = createSelector(
    getLabAccountFeatureState,
    state => state.labAccount
);

// export const getLabAccounts = createSelector(
//     getLabAccountFeatureState,
//     state => state.labAccounts
// );

export const getError = createSelector(
    getLabAccountFeatureState,
    state => state.error
);
