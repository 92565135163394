import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';

import { LabAppSettingsService } from '../lab-app-settings.service';
import { LabAppSettings } from '../lab-app-settings';

/* NgRx */
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as labAppSettingsActions from './lab-app-settings.actions';
import * as labAppSettingsActionsPublic from '.';

@Injectable()
export class LabAppSettingsEffects {

  constructor(private labAppSettingservice: LabAppSettingsService,
              private actions$: Actions) { }

  @Effect()
  loadLabAppSettings$: Observable<Action> = this.actions$.pipe(
    ofType(labAppSettingsActions.LabAppSettingsActionTypes.Load),
    // mergeMap(action =>
    map((action: labAppSettingsActionsPublic.Load) => action.payload),
    mergeMap((parameter: {}) =>
      this.labAppSettingservice.getLabAppSettings(parameter).pipe(
        tap(data => console.log('effects: loadLabAppSettings: ' + JSON.stringify(data))),
        map(labAppSettings => (new labAppSettingsActions.LoadSuccess(labAppSettings))),
        catchError(err => of(new labAppSettingsActions.LoadFail(err)))
      )
    )
  );



  @Effect()
  setLabAppSettings$: Observable<Action> = this.actions$.pipe(
    ofType(labAppSettingsActions.LabAppSettingsActionTypes.SetLabAppSettings),
    map((action: labAppSettingsActionsPublic.SetLabAppSettings) => action.payload),
    mergeMap((labAppSettings: LabAppSettings) =>
      this.labAppSettingservice.setLabAppSettings(labAppSettings).pipe(
        tap(data => console.log('effects: setLabAppSettings: ' + JSON.stringify(data))),

        map(newLabAppSettings => (new labAppSettingsActions.SetLabAppSettingsSuccess(newLabAppSettings))),
        catchError(err => of(new labAppSettingsActions.SetLabAppSettingsFail(err)))
      )
    )
  );



}
