import { Injectable } from '@angular/core';
import { UserManager, User, UserManagerSettings } from 'oidc-client';
import { Subject } from 'rxjs';
// import { CoreModule } from './core.module';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthContext } from '../models/auth-context';
import { Constants } from '../../constants';
import { ApiService } from './api.service';
import { LabAccount, ApiLabAccount } from '../lab-account/lab-account';



@Injectable({
  providedIn: 'root'
})
export class AuthServiceOidc {
  private _userManager: UserManager;
  private _user: User;
  private _loginChangedSubject = new Subject<boolean>();

  loginChanged = this._loginChangedSubject.asObservable();
  authContext: AuthContext;

  // private serializer = new AuthContextSerializer();
  private serializer = new LabAccountSerializer();


  // private authenticatedUrl = 'v1.0/authenticated';
  private labAccountsUrl = 'v1.0/me';


  constructor(
    private _httpClient: HttpClient,
    private api: ApiService,

  ) {
    const stsSettings: UserManagerSettings = {
      authority: Constants.stsAuthority,
      client_id: Constants.clientId,
      redirect_uri: `${Constants.clientRoot}signin-callback`,
      // scope: 'openid profile projects-api',
      scope: Constants.scope,
      // response_type: 'code', // Code Flow with PKCE
      response_type: 'id_token token', //  Implicit Flow
      post_logout_redirect_uri: `${Constants.clientRoot}signout-callback`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${Constants.clientRoot}assets/silent-callback.html`,

      // metadata: {
      //   issuer: `${Constants.stsAuthority}`,
      //   authorization_endpoint: `${Constants.stsAuthority}authorize?audience=projects-api`,
      //   jwks_uri: `${Constants.stsAuthority}.well-known/jwks.json`,
      //   token_endpoint: `${Constants.stsAuthority}oauth/token`,
      //   userinfo_endpoint: `${Constants.stsAuthority}userinfo`,
      //   end_session_endpoint: `${Constants.stsAuthority}v2/logout?client_id=${Constants.clientId}&returnTo=${encodeURI(Constants.clientRoot)}signout-callback`
      // }



      filterProtocolClaims: false,
      loadUserInfo: false,
    };
    this._userManager = new UserManager(stsSettings);
    this._userManager.events.addAccessTokenExpired(_ => {
      this._loginChangedSubject.next(false);
    });
    this._userManager.events.addUserLoaded(user => {
      if (this._user !== user) {
        this._user = user;
        this.loadSecurityContext();
        this._loginChangedSubject.next(!!user && !user.expired);
      }
    });

  }

  login() {
    return this._userManager.signinRedirect();
  }


  isLoggedIn(): Promise<boolean> {
    var userFromGetUser: User = null;
    var userCurrent = false;
    return this._userManager.getUser().then(user => {
      userFromGetUser = user;

      if (user != null && !user.expired) {
        userCurrent = true;
      }
      if (this._user !== user) {
        this._loginChangedSubject.next(userCurrent);
      }
      if (userCurrent && !this.authContext) {
        return this.loadSecurityContext();
      }
      return;
    }).then(() => {
      this._user = userFromGetUser;
      return userCurrent;
    }).catch(err => {
      return Promise.reject(err);
    });
  }

  completeLogin() {
    return this._userManager.signinRedirectCallback().then(user => {
      this._user = user;
      this._loginChangedSubject.next(!!user && !user.expired);
      return user;
    });
  }

  logout() {
    this._userManager.signoutRedirect();
  }

  completeLogout() {
    this._user = null;
    this._loginChangedSubject.next(false);
    return this._userManager.signoutRedirectCallback();
  }

  getAccessToken() {
    return this._userManager.getUser().then(user => {
      if (!!user && !user.expired) {
        return user.access_token;
      }
      else {
        return null;
      }
    });
  }

  loadSecurityContext() {

    return new Promise((resolve, reject) => {
      let reqOpts = {
        params: new HttpParams(),
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' }),
      };

      return this._httpClient
        .get<ApiLabAccount>(this.createUrl(this.labAccountsUrl), reqOpts)
        .toPromise()
        .then((context: any) => {
          // .subscribe(
          //   context => {
          console.log(`loadSecurityContext() context: ${JSON.stringify(context, null, 2)}`);

          var account = this.serializer.fromJson(context);

          // json.UserProfile,
          this.authContext = new AuthContext();
          this.authContext.userType = account.userType;
          resolve();

        },
          // error => console.error(error)
          error => {
            reject(error);
          }

        );
    });

  }


  // private createUrl(endpoint: string): string {
  //   let url = "";
  //   if (Constants.ServiceLocalhost) {
  //     url = Constants.SERVICE_URL_TESTSYSTEM + '/' + endpoint;
  //   } else {
  //     url = Constants.SERVICE_URL + '/' + endpoint; // + '?code=' + Constants.SERVICE_URL_CODE;
  //     // return Constants.SERVICE_URL + '/' + endpoint;
  //   }
  //   console.log(`createUrl(${endpoint}) -> url: ${url}`);
  //   return url;
  // }



  private createUrl(endpoint: string): string {
    // let TEST_SERVER_ACCESS = true;
    // if (TEST_SERVER_ACCESS){
      if (Constants.SETTINGS_CONSTS.SERVICE_LOCALHOST && Constants.SETTINGS_CONSTS.TEST_SERVER_ACCESS){
      // return Constants.SERVICE_URL + '/' + endpoint;
      return Constants.SERVICE_URL + '/' + endpoint + '?code=' + Constants.SERVICE_URL_CODE;
    }

    const isLocal = /localhost/.test(window.location.href);
    if (isLocal) {
      return Constants.SERVICE_URL_TESTSYSTEM + '/' + endpoint;
    } else {
      return Constants.SERVICE_URL + '/' + endpoint + '?code=' + Constants.SERVICE_URL_CODE;
      //return Constants.AZURE_BASE_URL + '/' + endpoint;
    }
  }


}



export class LabAccountSerializer {
  fromJson(json: ApiLabAccount): LabAccount {
    let settings = {};
    if (json.Settings != null && json.Settings.length > 0) {
      settings = JSON.parse(json.Settings)
    }
    const labAccount =
    {
      userId: json.UserId,
      domain: json.Domain,
      identity: json.Identity,
      displayName: json.DisplayName,
      email: json.Email,
      homeMapId: json.HomeMapId,
      settings: settings,
      userType: json.UserType,
      level: json.Level,
      imageURL: json.ImageURL,
    };

    return labAccount;
  }

  public createLabAccountName(id: string) {
    if (id != null && id.length >= 8) {
      return "labAccount_" + id.substr(0, 8);
    }
    return id;
  }


  toJson(labAccount: LabAccount): ApiLabAccount {
    return {
      UserId: labAccount.userId,
      Domain: labAccount.domain,
      Identity: labAccount.identity,
      DisplayName: labAccount.displayName,
      Email: labAccount.email,
      HomeMapId: labAccount.homeMapId,
      Settings: JSON.stringify(labAccount.settings),
      UserType: labAccount.userType,
      Level: labAccount.level,
      ImageURL: labAccount.imageURL,
    };
  }
}