import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Constants } from '../../constants';
import * as StackTrace from 'stacktrace-js';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ErrorService {


  constructor(
    public api: ApiService
  ) {
    console.log('Hello ErrorServiceProvider Provider');
  }


  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string {
    return error.message;
  }

  getServerStack(error: HttpErrorResponse): string {
    // handle stack trace
    return 'stack';
  }


  log(error: any): any {

    console.log(`Franz: ErrorService.log`);

    // console.error(`FRANZ: ERROR: ${JSON.stringify(error)}`);

    if (error == null || (typeof error === 'string' && error.length === 0)) {
      console.log(`FRANZ: ErrorService.log error=empty`);
      return;
    }
    console.log(`FRANZ: ErrorService.log ERROR: ${error}`);

    if (typeof error === 'string') {
      this.audit(error, "", "");
    } else {

      // from https://medium.com/@amcdnl/global-error-handling-with-angular2-6b992bdfb59c

      const message = error.message ? error.message : error.toString();
      // const url = location instanceof HashLocationStrategy ? location.path() : '';
      const path = location ? location.hash : '';

      // get the stack trace, lets grab the last 10 stacks only
      StackTrace.fromError(error).then(stackframes => {
        const stackString = stackframes
          .splice(0, 20)
          .map(function (sf) {
            return sf.toString();
          }).join('\n');
        // log on the server
        // console.error(`FRANZ: ERROR: ${message}, url: ${url}, stack: ${stackString}`);
        this.audit(message, path, stackString);
      });
    }

    // return throwError("Method not implemented.");
  }




  private audit(message: string, path: string, stack: string): Promise<{}> {

    //  http://localhost:7071/api/v1.0/audit


    if (stack != null && stack.length > 5000) {
      stack = stack.substr(0, 5000);
    }

    let url = `v1.0/audit`;

    var currentDate = new Date().toISOString();
    var version = Constants.CLIENT_VERSION;
    var sessionId = ""; // TODO

    let envInfo = `${currentDate};${version};${sessionId}`;

    return new Promise((resolve, reject) => {
      let params = null;
      let editObject =
      {
        "url": path,
        "env": envInfo,
        "message": message,
        "stack": stack,
      };

      let body = JSON.stringify(editObject);

      this.api.put(url, body, params)
        .subscribe((data: any) => {
          resolve({});
        }, (error) => {
          console.error('An error occurred', error);
          // hide errors
          // return Promise.reject(error.message || error);
        });

    });


  }


}
