import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { LabAccount, ApiLabAccount } from './lab-account';
import { ApiService } from '../services/api.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LabAccountService {
  private labAccountsUrl = 'v1.0/me';
  private serializer = new LabAccountSerializer();

  constructor(
    private api: ApiService) { }

  getLabAccounts(): Observable<LabAccount[]> {

    let params = null;
    return this.api.get(this.labAccountsUrl, params)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        map((data: any) => this.convertDataLoeschen<LabAccount>(data)),
        catchError(this.handleError)
      );
  }

  getLabAccount(): Observable<LabAccount> {

    let params = null;
    return this.api.get(this.labAccountsUrl, params)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        map((data: any) => this.convertData<LabAccount>(data)),
        catchError(this.handleError)
      );
  }


  updateLabAccount(labAccount: LabAccount): Observable<LabAccount> {

    let paramObj = {
      "DisplayName": labAccount.displayName,
      "Email": labAccount.email,
      "Settings": JSON.stringify(labAccount.settings)
    };
    let body = JSON.stringify(paramObj);
    return this.api.post(this.labAccountsUrl, body)

      .pipe(
        // tap(() => console.log('updateLabAccount: ' + labAccount.userId)),
        // Return the labAccount on an update
        map(() => labAccount),
        catchError(this.handleError)
      );
  }


  createLabAccount(labAccount: LabAccount): Observable<LabAccount> {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // const headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/json');
    // headers.append('Accept', 'application/json');

    // const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    let paramObj = {
      // "DisplayName": "",
      // "Email": labAccount.email,
      // "Settings": JSON.stringify(labAccount.settings)
    };
    let body = JSON.stringify(paramObj);
    return this.api.put(this.labAccountsUrl, body)
      .pipe(
        tap((userInfo) => {
           console.log(`registerLabAccount (${labAccount}): ${JSON.stringify(userInfo)}`);
        }),
        map((data: any) => this.convertData<LabAccount>(data)),
        catchError(this.handleError)
      );
  }


  private convertDataLoeschen<T>(data: any): T[] {
    let array: T[] = new Array<T>();
    let item: any = this.serializer.fromJson(data);
    array.push(item);
    return array;
  }


  private convertData<T>(data: any): T {
    let item: any = this.serializer.fromJson(data);
    return item;
  }
  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else
      if (typeof err === 'string') {
        errorMessage = err;
      }
      else {
        // // The backend returned an unsuccessful response code.
        // // The response body may contain clues as to what went wrong,
        errorMessage = `An error occurred ${err} / ${JSON.stringify(err)}`;
      }
    return throwError(err);
  }

}


export class LabAccountSerializer {
  fromJson(json: ApiLabAccount): LabAccount {
    let settings = {};
    if (json.Settings != null && json.Settings.length > 0) {
      settings = JSON.parse(json.Settings)
    }
    const labAccount =
    {
      userId: json.UserId,
      domain: json.Domain,
      identity: json.Identity,
      displayName: json.DisplayName,
      email: json.Email,
      homeMapId: json.HomeMapId,
      settings: settings,
      userType: json.UserType,
      level: json.Level,
      imageURL: json.ImageURL,
    };

    return labAccount;
  }

  public createLabAccountName(id: string) {
    if (id != null && id.length >= 8) {
      return "labAccount_" + id.substr(0, 8);
    }
    return id;
  }


  toJson(labAccount: LabAccount): ApiLabAccount {
    return {
      UserId: labAccount.userId,
      Domain: labAccount.domain,
      Identity: labAccount.identity,
      DisplayName: labAccount.displayName,
      Email: labAccount.email,
      HomeMapId: labAccount.homeMapId,
      Settings: JSON.stringify(labAccount.settings),
      UserType: labAccount.userType,
      Level: labAccount.level,
      ImageURL: labAccount.imageURL,
    };
  }
}