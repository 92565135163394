import { LabAppSettings } from '../lab-app-settings';
import { LabAppSettingsActionTypes } from './lab-app-settings.actions';
import { LabAppSettingsActions } from '.';

export interface LabAppSettingsState {
  labAppSettings: LabAppSettings;
  error: string;

}

const initialState: LabAppSettingsState = {
  labAppSettings: {
    currentMapId: "",
    currentBoxId: "",
    currentPackageId: "",
    isDemo: false,
    darkMode: null,
  },
  error: '',
};

export function reducer(state = initialState, action: LabAppSettingsActions): LabAppSettingsState {
  switch (action.type) {

    case LabAppSettingsActionTypes.LoadSuccess:
      return {
        ...state,
        labAppSettings: action.payload,
        error: ''
      };

    case LabAppSettingsActionTypes.LoadFail:
      return {
        ...state,
        error: action.payload
      };

    case LabAppSettingsActionTypes.SetLabAppSettingsSuccess:

      return {
        ...state,
        labAppSettings: action.payload,
        error: ''
      };

    case LabAppSettingsActionTypes.SetLabAppSettingsFail:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
}
