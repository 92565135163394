import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'in-out-connection',
  templateUrl: './in-out-connection.component.html',
  styleUrls: ['./in-out-connection.component.scss'],
})
export class InOutConnectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
