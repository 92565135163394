export enum UserType {
  UNKNOWN = 0,
  BOT = 1,
  SYSTEM = 2,
  CUSTOMER = 3,
}

export class AuthContext {
  userType: number = -1;

  public isAdmin() {
    return (this.userType == UserType.SYSTEM);
  }
}



