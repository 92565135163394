import { LabMap } from '../lab-map';
import { LabMapActionTypes, LabMapActions } from './lab-map.actions';

// State for this feature (LabMap)
export interface LabMapState {
  showLabMapId: boolean;
  currentLabMapId: string | null;
  labMaps: LabMap[];
  error: string;
}

const initialState: LabMapState = {
  showLabMapId: true,
  currentLabMapId: null,
  labMaps: [],
  error: ''
};

export function reducer(state = initialState, action: LabMapActions): LabMapState {

  switch (action.type) {
    case LabMapActionTypes.ToggleLabMapCode:
      return {
        ...state,
        showLabMapId: action.payload
      };

    case LabMapActionTypes.SetCurrentLabMap:
      return {
        ...state,
        currentLabMapId: action.payload.id
      };

    case LabMapActionTypes.ClearCurrentLabMap:
      return {
        ...state,
        currentLabMapId: null
      };

    case LabMapActionTypes.InitializeCurrentLabMap:
      return {
        ...state,
        currentLabMapId: "0"
      };

    case LabMapActionTypes.LoadSuccess:
      return {
        ...state,
        labMaps: action.payload,
        error: ''
      };

    case LabMapActionTypes.LoadFail:
      return {
        ...state,
        labMaps: [],
        error: action.payload
      };

    case LabMapActionTypes.UpdateLabMapSuccess:
      const updatedLabMaps = state.labMaps.map(
        item => action.payload.id === item.id ? action.payload : item);
      return {
        ...state,
        labMaps: updatedLabMaps,
        currentLabMapId: action.payload.id,
        error: ''
      };

    case LabMapActionTypes.UpdateLabMapFail:
      return {
        ...state,
        error: action.payload
      };

    // After a create, the currentLabMap is the new labMap.
    case LabMapActionTypes.CreateLabMapSuccess:
      return {
        ...state,
        labMaps: [...state.labMaps, action.payload],
        currentLabMapId: action.payload.id,
        error: ''
      };

    case LabMapActionTypes.CreateLabMapFail:
      return {
        ...state,
        error: action.payload
      };

    // After a delete, the currentLabMap is null.
    case LabMapActionTypes.DeleteLabMapSuccess:
      return {
        ...state,
        labMaps: state.labMaps.filter(labMap => labMap.id !== action.payload),
        currentLabMapId: null,
        error: ''
      };

    case LabMapActionTypes.DeleteLabMapFail:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
}
