import { Component, OnInit } from '@angular/core';
import { AuthServiceOidc } from '../../services/auth-service.component';

@Component({
  selector: 'app-unauthorized',
  templateUrl: 'unauthorized.component.html'
})

export class UnauthorizedComponent implements OnInit {
  constructor(private _authService: AuthServiceOidc) { }

  ngOnInit() { }

  logout() {
    this._authService.logout();
  }
}