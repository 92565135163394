import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { LabAccountService } from '../lab-account.service';
import { LabAccount } from '../lab-account';

/* NgRx */
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as labAccountActions from './lab-account.actions';

@Injectable()
export class LabAccountEffects {

  constructor(private labAccountService: LabAccountService,
              private actions$: Actions) { }

  // @Effect()
  // loadLabAccount$: Observable<Action> = this.actions$.pipe(
  //   ofType(labAccountActions.LabAccountActionTypes.Load),
  //   mergeMap(action =>
  //     this.labAccountService.getLabAccount().pipe(
  //       map(labAccount => (new labAccountActions.LoadSuccess(labAccount))),
  //       catchError(err => of(new labAccountActions.LoadFail(err)))
  //     )
  //   )
  // );


  @Effect()
  loadLabAccount$: Observable<Action> = this.actions$.pipe(
    ofType(labAccountActions.LabAccountActionTypes.Load),
    mergeMap(action =>
      this.labAccountService.getLabAccount().pipe(
        map(labAccount => (new labAccountActions.LoadSuccess(labAccount))),
        catchError(err => of(new labAccountActions.LoadFail(err)))
      )
    )
  );

  @Effect()
  updateLabAccount$: Observable<Action> = this.actions$.pipe(
    ofType(labAccountActions.LabAccountActionTypes.UpdateLabAccount),
    map((action: labAccountActions.UpdateLabAccount) => action.payload),
    mergeMap((labAccount: LabAccount) =>
      this.labAccountService.updateLabAccount(labAccount).pipe(
        map(updatedLabAccount => (new labAccountActions.UpdateLabAccountSuccess(updatedLabAccount))),
        catchError(err => of(new labAccountActions.UpdateLabAccountFail(err)))
      )
    )
  );

  @Effect()
  createLabAccount$: Observable<Action> = this.actions$.pipe(
    ofType(labAccountActions.LabAccountActionTypes.CreateLabAccount),
    map((action: labAccountActions.CreateLabAccount) => action.payload),
    mergeMap((labAccount: LabAccount) =>
      this.labAccountService.createLabAccount(labAccount).pipe(
        map(newLabAccount => (new labAccountActions.CreateLabAccountSuccess(newLabAccount))),
        catchError(err => of(new labAccountActions.CreateLabAccountFail(err)))
      )
    )
  );

  // @Effect()
  // deleteLabAccount$: Observable<Action> = this.actions$.pipe(
  //   ofType(labAccountActions.LabAccountActionTypes.DeleteLabAccount),
  //   map((action: labAccountActions.DeleteLabAccount) => action.payload),
  //   mergeMap((labAccountId: string) =>
  //     this.labAccountService.deleteLabAccount(labAccountId).pipe(
  //       map(() => (new labAccountActions.DeleteLabAccountSuccess(labAccountId))),
  //       catchError(err => of(new labAccountActions.DeleteLabAccountFail(err)))
  //     )
  //   )
  // );
}
