import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { LabBox, ApiLabBox } from './lab-box';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class LabBoxService {
  // private boxesUrl = 'api/lab-boxes';
  private labBoxesUrl = 'v1.0/me/boxes';
  private serializer = new LabBoxSerializer();

  constructor(
    private http: HttpClient,
    private api: ApiService) { }

  getLabBoxes(): Observable<LabBox[]> {

    let params = null;
    return this.api.get(this.labBoxesUrl, params)
      .pipe(
        // tap(data => console.log(JSON.stringify(data))),
        map((data: any) => this.convertData<LabBox>(data)),

        catchError(this.handleError)
      );
  }


  createLabBox(labBox: LabBox): Observable<LabBox> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    labBox.id = null;
    return this.api.post<LabBox>(this.labBoxesUrl, labBox, { headers: headers })
      .pipe(
        // tap(data => console.log('createLabBox: ' + JSON.stringify(data))),
        map(() => labBox),
        catchError(this.handleError)
      );
  }

  deleteLabBox(id: string): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.labBoxesUrl}/${id}`;
    return this.api.delete<LabBox>(url, { headers: headers })
      .pipe(
        // tap(data => console.log('deleteLabBox: ' + id)),
        catchError(this.handleError)
      );
  }

  updateLabBox(labBox: LabBox): Observable<LabBox> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.labBoxesUrl}/${labBox.id}`;
    return this.api.put<LabBox>(url, labBox, { headers: headers })
      .pipe(
        // tap(() => console.log('updateLabBox: ' + labBox.id)),
        // Return the box on an update
        map(() => labBox),
        catchError(this.handleError)
      );
  }

  updateLabBoxConnectToFunction(labBox: LabBox): Observable<LabBox> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });


    let mapId = labBox.mapId;
    let boxId = labBox.id;
  
  
        //  /api/v1.0/me/maps/dc9bb248-7576-4121-bb53-48d36d76f479/boxes/4581e8c6-d464-4563-a9cb-32325e6807a5/packages/7926c4d2-2385-49c0-aa0d-06edb9e27d14/content
  
  
        if (mapId == null || mapId.length == 0) {
          return throwError("LabBoxService::updateLabBoxConnectToFunction(): missing mapId");
        }
    
        if (boxId == null || boxId.length == 0) {
          return throwError("LabBoxService::updateLabBoxConnectToFunction(): missing boxId");
        }

        let url = `v1.0/me/maps/${mapId}/boxes/${boxId}`;


    var parameter = {
      "azFctUrl": labBox["azFctUrl"],
      "secret": labBox["secret"],
    };
    // return this.api.post<LabBox>(url, parameter, { headers: headers })
    return this.api.post(url, parameter, { headers: headers })
      .pipe(
        // tap(() => console.log('updateLabBox: ' + labBox.id)),
        // Return the box on an update
        map(() => labBox),
        catchError(this.handleError)
      );
  }



  sendLabBoxMessage(labBox: LabBox): Observable<LabBox> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // const url = `${this.labBoxsUrl}/${labBox.id}`;
    let mapId = labBox.mapId;
    let boxId = labBox.id;

    // https://kesakten.azurewebsites.net/api/v1.0/me/maps/dc9bb248-7576-4121-bb53-48d36d76f479/boxes/4581e8c6-d464-4563-a9cb-32325e6807a5/packages/7926c4d2-2385-49c0-aa0d-06edb9e27d14/route?code={{ServiceUrlCode}}

    if (mapId == null || mapId.length == 0) {
      return throwError("LabBoxService::sendLabBoxMessage(): missing mapId");
    }

    if (boxId == null || boxId.length == 0) {
      return throwError("LabBoxService::sendLabBoxMessage(): missing boxId");
    }
    let url = `v1.0/me/maps/${mapId}/boxes/${boxId}/packages`;

    var parameter = {
      "outputId": labBox["outputId"],
      "message": labBox["message"],
      "routingHint": labBox["routingHint"],
      "toMapId": labBox["toMapId"],
      "toBoxId": labBox["toBoxId"],
    };
    return this.api.put(url, parameter, { headers: headers })
      .pipe(
        // tap(() => console.log('sendLabBoxMessage: ' + labBox.id)),
        // Return the labBox on an update
        map(() => labBox),
        catchError(this.handleError)
      );
  }



  private convertData<T>(data: any): T[] {
    return data.map((item: any) => this.serializer.fromJson(item));
  }

  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else
      if (typeof err === 'string') {
        errorMessage = err;
      }
      else {
        // // The backend returned an unsuccessful response code.
        // // The response body may contain clues as to what went wrong,
        // errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
        errorMessage = `An error occurred ${err} / ${JSON.stringify(err)}`;
      }
    // console.error(err);
    return throwError(err);
    // return throwError(errorMessage);
  }

}


export class LabBoxSerializer {
  fromJson(json: ApiLabBox): LabBox {
    const labBox =
    {
      id: json.Id,
      name: json.Name == null ? this.createLabBoxName(json.Id): json.Name,
      mapId: json.MapId,
      properties: json.Properties,
    };

    return labBox;
  }

  public createLabBoxName(id: string) {
    if (id != null && id.length >= 8) {
      return "labBox_" + id.substr(0, 8);
    }
    return id;
  }


  toJson(labBox: LabBox): ApiLabBox {
    return {
      Id: labBox.id,
      Name: labBox.name,
      MapId: labBox.mapId,
      Properties: labBox.properties
    };
  }
}