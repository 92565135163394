import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreditService {

  constructor(
    public http: HttpClient
    ) {
    console.log('Hello CreditServiceProvider Provider');
  }


  getValue() {

  }
  getTransactions() {

  }


  
  // private handleError(error: any): Promise<any> {
    // console.error(`${this.constructor.name}: An error occurred`, error);
  //   return Promise.reject(error.message || error);
  // }


}
