import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CheckTutorial } from "./providers/check-tutorial.service";
import { SignoutRedirectCallbackComponent } from './components/signinout-redirect/signout-redirect-callback.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tutorial',
    pathMatch: 'full'
  },
  {
    path: 'lab-start',
    loadChildren: () => import('./lab-start/lab-start.module').then(m => m.LabStartModule)
  },
  {
    path: 'lab-maps',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./lab-maps/lab-map.module').then(m => m.LabMapModule)
  },
  {
    path: 'lab-boxes',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./lab-boxes/lab-box.module').then(m => m.LabBoxModule)
  },
  {
    path: 'lab-packages',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./lab-packages/lab-package.module').then(m => m.LabPackageModule)
  },
  {
    path: 'lab-audits',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./lab-audits/lab-audit.module').then(m => m.LabAuditModule)
  },
  {
    path: 'lab-account',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./lab-account/lab-account.module').then(m => m.LabAccountModule)
  },
  {
    path: 'lab-app-settings',
    // component: LabAppSettingsShellComponent,
    loadChildren: () => import('./lab-app-settings/lab-app-settings.module').then(m => m.LabAppSettingsModule)
  },
  {
    path: 'lab-credit',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./lab-credit/lab-credit.module').then(m => m.LabCreditModule)
  },

  {
    path: "box-list",
    loadChildren: () => import('./pages/box-list/box-list.module').then(m => m.BoxListPageModule)
  },
  {
    path: "account-info",
    loadChildren: () => import('./pages/account-info/account-info.module').then(m => m.AccountInfoPageModule)
  },
  {
    path: "about",
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: "box-create",
    loadChildren: () => import('./pages/box-create/box-create.module').then(m => m.BoxCreatePageModule)
  },
  {
    path: "box-detail/:mapid/:id",
    loadChildren: () => import('./pages/box-detail/box-detail.module').then(m => m.BoxDetailPageModule)
  },
  {
    path: "credit-info",
    loadChildren: () => import('./pages/credit-info/credit-info.module').then(m => m.CreditInfoPageModule)
  },
  {
    path: "package-detail/:mapid/:boxid/:id",
    loadChildren: () => import('./pages/package-detail/package-detail.module').then(m => m.PackageDetailPageModule)
  },
  {
    path: "package-list",
    loadChildren: () => import('./pages/package-list/package-list.module').then(m => m.PackageListPageModule)
  },
  {
    path: "settings",
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: "welcome",
    loadChildren: "./pages/welcome/welcome.module#WelcomePageModule"
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'signin-callback',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./lab-account-signin-callback/lab-account-signin-callback.module').then(m => m.LabAccountSigninCallbackModule)
  },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
