import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { KtMap } from '../models/ktmap';

@Injectable({
  providedIn: 'root'
})
export class MapService {


  constructor(
      public api: ApiService
  ) {
    console.log('Hello MapServiceProvider Provider');
  }


  getMaps(): Promise<KtMap[]> {

    let url = 'v1.0/me/maps';

    return new Promise((resolve, reject) => {
      let params = null;
      this.api.get(url, params)
        .subscribe((data: any) => {
          console.log(`FRANZ: data: const maps = ${JSON.stringify(data)}`);
          var dataObjects = data as Object[];

          // let item: KtAccount = new KtAccount(dataObject);
          // resolve(item);

          // var items = this.mapDataArray(dataObjects) as Item[];

          let items: KtMap[] = [];
          for (let item of dataObjects) {
            items.push(new KtMap(item));
          }
          resolve(items);

        }, (error) => {
          this.handleError(error);
        });
    });

  }

    

  submitMap() {

  }


  
  private handleError(error: any): Promise<any> {
    console.error(`${this.constructor.name}: An error occurred`, error);
    return Promise.reject(error.message || error);
  }


}
