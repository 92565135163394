import { LabAccount } from '../lab-account';
import { LabAccountActionTypes, LabAccountActions } from './lab-account.actions';

// State for this feature (LabAccount)
export interface LabAccountState {
  showLabAccountId: boolean;
  currentLabAccountId: string | null;
  // labAccounts: LabAccount[];
  labAccount: LabAccount;
  error: string;
}

const initialState: LabAccountState = {
  showLabAccountId: true,
  currentLabAccountId: null,
  // labAccounts: [],
  labAccount: null,
  error: ''
};

export function reducer(state = initialState, action: LabAccountActions): LabAccountState {

  switch (action.type) {
    case LabAccountActionTypes.ToggleLabAccountCode:
      return {
        ...state,
        showLabAccountId: action.payload
      };

    // case LabAccountActionTypes.SetCurrentLabAccount:
    //   return {
    //     ...state,
    //     currentLabAccountId: action.payload.userId
    //   };

    // case LabAccountActionTypes.ClearCurrentLabAccount:
    //   return {
    //     ...state,
    //     currentLabAccountId: null
    //   };

    case LabAccountActionTypes.InitializeCurrentLabAccount:
      return {
        ...state,
        currentLabAccountId: "0"
      };

    case LabAccountActionTypes.LoadSuccess:
      return {
        ...state,
        labAccount: action.payload,
        error: ''
      };

    case LabAccountActionTypes.LoadFail:
      return {
        ...state,
        labAccount: null,
        error: action.payload
      };

    case LabAccountActionTypes.UpdateLabAccountSuccess:
      const updatedLabAccount = state.labAccount; 
      return {
        ...state,
        labAccount: updatedLabAccount,
        currentLabAccountId: action.payload.userId,
        error: ''
      };

    case LabAccountActionTypes.UpdateLabAccountFail:
      return {
        ...state,
        error: action.payload
      };

    // After a create, the currentLabAccount is the new labAccount.
    case LabAccountActionTypes.CreateLabAccountSuccess:
      return {
        ...state,
        // labAccount: [...state.labAccount, action.payload],
        labAccount: action.payload,
        currentLabAccountId: action.payload.userId,
        error: ''
      };

    case LabAccountActionTypes.CreateLabAccountFail:
      return {
        ...state,
        error: action.payload
      };

    // // After a delete, the currentLabAccount is null.
    // case LabAccountActionTypes.DeleteLabAccountSuccess:
    //   return {
    //     ...state,
    //     labAccounts: state.labAccounts.filter(labAccount => labAccount.userId !== action.payload),
    //     currentLabAccountId: null,
    //     error: ''
    //   };

    // case LabAccountActionTypes.DeleteLabAccountFail:
    //   return {
    //     ...state,
    //     error: action.payload
    //   };

    default:
      return state;
  }
}
