import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LabAppSettings } from '../../lab-app-settings';
import { GenericValidator } from '../../../shared/generic-validator';
import { NumberValidators } from '../../../shared/number.validator';

@Component({
  selector: 'lab-app-settings-edit',
  templateUrl: './lab-app-settings-edit.component.html',
  styleUrls: ['./lab-app-settings-edit.component.scss']
})
export class LabAppSettingsEditComponent implements OnInit, OnChanges, OnDestroy {
  pageTitle = 'LabAppSettings Edit';
  @Input() errorMessage: string;
  @Input() selectedLabAppSettings: LabAppSettings;
  @Output() update = new EventEmitter<LabAppSettings>();

  componentActive = true;
  labAppSettingsForm: FormGroup;

  labAppSettings: LabAppSettings | null;

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  constructor(private fb: FormBuilder) {
    // Defines all of the validation messages for the form.
    // These could instead be retrieved from a file or database.
    this.validationMessages = {
      // name: {
      //   required: 'LabAppSettings name is required.',
      //   minlength: 'LabAppSettings name must be at least three characters.',
      //   maxlength: 'LabAppSettings name cannot exceed 50 characters.'
      // },
      // mapId: {
      //   required: 'LabAppSettings code is required.'
      // },
      // starRating: {
      //   range: 'Rate the labAppSettings between 1 (lowest) and 5 (highest).'
      // }
    };

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {



    // Define the form group
    this.labAppSettingsForm = this.fb.group({
      currentMapId: '',
      currentBoxId: '',
      currentPackageId: '',
      darkMode: false,
    });

    // Watch for value changes
    this.labAppSettingsForm.valueChanges.subscribe(
      value => this.displayMessage = this.genericValidator.processMessages(this.labAppSettingsForm)
    );

    if (this.selectedLabAppSettings != null) {
      this.displayLabAppSettings(this.selectedLabAppSettings);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    // patch form with value from the store
    if (changes.selectedLabAppSettings) {
      const labAppSettings: any = changes.selectedLabAppSettings.currentValue as LabAppSettings;
      this.displayLabAppSettings(labAppSettings);
    }
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  // Also validate on blur
  // Helpful if the user tabs through required fields
  blur(): void {
    this.displayMessage = this.genericValidator.processMessages(this.labAppSettingsForm);
  }

  displayLabAppSettings(labAppSettings: LabAppSettings | null): void {
    // Set the local labAppSettings property
    this.labAppSettings = labAppSettings;

    if (this.labAppSettings && this.labAppSettingsForm) {
      // Reset the form back to pristine
      this.labAppSettingsForm.reset();

      this.pageTitle = `Edit LabAppSettings`;

      // Update the data on the form
      this.labAppSettingsForm.patchValue({
        currentMapId: this.labAppSettings.currentMapId,
        currentBoxId: this.labAppSettings.currentBoxId,
        currentPackageId: this.labAppSettings.currentPackageId,
        darkMode: this.labAppSettings.darkMode,
      });
    }
  }

  cancelEdit(): void {
    // Redisplay the currently selected labAppSettings
    // replacing any edits made
    this.displayLabAppSettings(this.labAppSettings);
  }


  saveLabAppSettings(): void {
    if (this.labAppSettingsForm.valid) {
      if (this.labAppSettingsForm.dirty) {
        // Copy over all of the original labAppSettings properties
        // Then copy over the values from the form
        // This ensures values not on the form, such as the Id, are retained
        const p = { ...this.labAppSettings, ...this.labAppSettingsForm.value };

        // if (p.id === 0) {
        //   this.create.emit(p);
        // } else {
        this.update.emit(p);
        // }
      }
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

}
