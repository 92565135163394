import { LabBox } from '../lab-box';
import { LabBoxActionTypes, LabBoxActions } from './lab-box.actions';

// State for this feature (LabBox)
export interface LabBoxState {
  showLabBoxId: boolean;
  currentLabBoxId: string | null;
  labBoxes: LabBox[];
  error: string;
}

const initialState: LabBoxState = {
  showLabBoxId: true,
  currentLabBoxId: null,
  labBoxes: [],
  error: ''
};

export function reducer(state = initialState, action: LabBoxActions): LabBoxState {

  switch (action.type) {
    case LabBoxActionTypes.ToggleLabBoxCode:
      return {
        ...state,
        showLabBoxId: action.payload
      };

    case LabBoxActionTypes.SetCurrentLabBox:
      return {
        ...state,
        currentLabBoxId: action.payload.id
      };

    case LabBoxActionTypes.ClearCurrentLabBox:
      return {
        ...state,
        currentLabBoxId: null
      };

    case LabBoxActionTypes.InitializeCurrentLabBox:
      return {
        ...state,
        currentLabBoxId: "0"
      };

    case LabBoxActionTypes.LoadSuccess:
      return {
        ...state,
        labBoxes: action.payload,
        error: ''
      };

    case LabBoxActionTypes.LoadFail:
      return {
        ...state,
        labBoxes: [],
        error: action.payload
      };

    case LabBoxActionTypes.UpdateLabBoxSuccess:
      const updatedLabBoxes = state.labBoxes.map(
        item => action.payload.id === item.id ? action.payload : item);
      return {
        ...state,
        labBoxes: updatedLabBoxes,
        currentLabBoxId: action.payload.id,
        error: ''
      };

    case LabBoxActionTypes.UpdateLabBoxFail:
      return {
        ...state,
        error: action.payload
      };


    case LabBoxActionTypes.UpdateLabBoxConnectToFunctionSuccess:
      // const updatedLabBoxesConnectToFunction = state.labBoxes.map(
      //   item => action.payload.id === item.id ? action.payload : item);
      return {
        ...state,
        // TODO not update this, but perhaps we should add a flag, that it is set
        // labBoxes: ConnectToFunction,
        currentLabBoxId: action.payload.id,
        error: ''
      };

    case LabBoxActionTypes.UpdateLabBoxConnectToFunctionFail:
      return {
        ...state,
        error: action.payload
      };


    case LabBoxActionTypes.SendLabBoxMessageSuccess:
      return {
        ...state,
        error: ''
      };

    case LabBoxActionTypes.SendLabBoxMessageFail:
      return {
        ...state,
        error: action.payload
      };

    // After a create, the currentLabBox is the new labBox.
    case LabBoxActionTypes.CreateLabBoxSuccess:
      return {
        ...state,
        labBoxes: [...state.labBoxes, action.payload],
        currentLabBoxId: action.payload.id,
        error: ''
      };

    case LabBoxActionTypes.CreateLabBoxFail:
      return {
        ...state,
        error: action.payload
      };

    // After a delete, the currentLabBox is null.
    case LabBoxActionTypes.DeleteLabBoxSuccess:
      return {
        ...state,
        labBoxes: state.labBoxes.filter(labBox => labBox.id !== action.payload),
        currentLabBoxId: null,
        error: ''
      };

    case LabBoxActionTypes.DeleteLabBoxFail:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
}
