import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LabBox } from '../../lab-box';
import { GenericValidator } from '../../../shared/generic-validator';
import { NumberValidators } from '../../../shared/number.validator';
import { ModalController, NavParams } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'lab-box-connect-function-modal',
  templateUrl: './lab-box-connect-function-modal.component.html',
  styleUrls: ['./lab-box-connect-function-modal.component.scss']
})
export class LabBoxConnectFunctionModalComponent implements OnInit, OnChanges, OnDestroy {

  static readonly ROLE_ADD: string = "add";
  static readonly ROLE_UPDATE: string = "update";

  static readonly ROLE_UPDATE_CONNECT_TO_FUNCTION: string = "update connect to function";

  subscriptions: Subscription[] = [];


  pageTitle = 'LabBox Modal';
  @Input() errorMessage$: Observable<string>;
  @Input() selectedLabBox$: Observable<LabBox>;

  componentActive = true;
  labBoxForm: FormGroup;

  labBox: LabBox | null;

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  displayErrorMessage: string = null;

  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private navParams: NavParams,
  ) {
    // Defines all of the validation messages for the form.
    // These could instead be retrieved from a file or database.
    this.validationMessages = {
      azFctUrl: {
        required: 'Azure Function URL is required.'
      },
      secret: {
        required: 'Secret is required.'
      },
    };

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    // Define the form group
    this.labBoxForm = this.fb.group({

      azFctUrl: ['', Validators.required],
      secret: ['', Validators.required],

    });

    // Watch for value changes
    this.labBoxForm.valueChanges.subscribe(
      value => this.displayMessage = this.genericValidator.processMessages(this.labBoxForm)
    );


    if (this.selectedLabBox$) {
      this.subscriptions.push(
        this.selectedLabBox$.subscribe(
          labBox => {
            this.displayLabBox(labBox);
          }
        )
      );
    }

    if (this.errorMessage$) {
      this.subscriptions.push(
        this.errorMessage$.subscribe(
          errorMessage => {
            this.displayErrorMessage = errorMessage;
          }
        )
      );
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
  }





  ngOnDestroy(): void {
    this.componentActive = false;
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  // Also validate on blur
  // Helpful if the user tabs through required fields
  blur(): void {
    this.displayMessage = this.genericValidator.processMessages(this.labBoxForm);
  }

  displayLabBox(labBox: LabBox | null): void {
    // Set the local labBox property
    this.labBox = labBox;

    if (this.labBox && this.labBoxForm) {
      // Reset the form back to pristine
      this.labBoxForm.reset();

      this.pageTitle = 'Connect to function';

      // Update the data on the form
      this.labBoxForm.patchValue({
        azFctUrl: '',
        secret: '',
      });
    }
  }


  cancelModal(): void {
    this.dismiss(null);
  }


  saveLabBox(): void {
    if (this.labBoxForm.valid) {
      if (this.labBoxForm.dirty) {
        // Copy over all of the original labBox properties
        // Then copy over the values from the form
        // This ensures values not on the form, such as the Id, are retained
        const p = { ...this.labBox, ...this.labBoxForm.value };
    
        this.dismiss(p);
      }
    } else {
      this.displayErrorMessage = 'Please correct the validation errors.';
    }
  }


  async dismiss(labBox: LabBox | null) {
    const data: LabBox | null = labBox;
    let role = "";

    if (labBox !== null) {
      role = LabBoxConnectFunctionModalComponent.ROLE_UPDATE_CONNECT_TO_FUNCTION;
    }
    await this.modalController.dismiss(data, role);
  }

}
