export class KtPackage {

    public timestamp: string;
    public mapId: string;
    public boxId: string;
    public messageId: string;
    public fromBoxId: string;
    public fromUserId: string;
    public inputId: number;
    public routingHint_OutputId: number;
    public status: number;


    constructor(dataObject?: any) {
        if (dataObject != null) {
            if (dataObject.Timestamp != null) { this.timestamp = dataObject.Timestamp; }
            if (dataObject.MapId != null) { this.mapId = dataObject.MapId; }
            if (dataObject.BoxId != null) { this.boxId = dataObject.BoxId; }
            if (dataObject.MessageId != null) { this.messageId = dataObject.MessageId; }
            if (dataObject.FromBoxId != null) { this.fromBoxId = dataObject.FromBoxId; }
            if (dataObject.FromUserId != null) { this.fromUserId = dataObject.FromUserId; }
            if (dataObject.InputId != null) { this.inputId = dataObject.InputId; }
            if (dataObject.RoutingHint_OutputId != null) { this.routingHint_OutputId = dataObject.RoutingHint_OutputId; }
            if (dataObject.Status != null) { this.status = dataObject.Status; }
        }
    }

    public getEditObject() {
        let itemTmp = {
            // "Name": this.name,
        }
        return itemTmp;
    }

}

