import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { from } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage: Storage
  ) { }

  set<T>(key: string, value: string) {
    return from(this.storage.set(key, value));
  }
  get(key: string) {
    return from(this.storage.get(key));
  }


}
