import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { LabMapService } from '../lab-map.service';
import { LabMap } from '../lab-map';

/* NgRx */
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as labMapActions from './lab-map.actions';

@Injectable()
export class LabMapEffects {

  constructor(private labMapService: LabMapService,
              private actions$: Actions) { }

  @Effect()
  loadLabMaps$: Observable<Action> = this.actions$.pipe(
    ofType(labMapActions.LabMapActionTypes.Load),
    mergeMap(action =>
      this.labMapService.getLabMaps().pipe(
        map(labMaps => (new labMapActions.LoadSuccess(labMaps))),
        catchError(err => of(new labMapActions.LoadFail(err)))
      )
    )
  );

  @Effect()
  updateLabMap$: Observable<Action> = this.actions$.pipe(
    ofType(labMapActions.LabMapActionTypes.UpdateLabMap),
    map((action: labMapActions.UpdateLabMap) => action.payload),
    mergeMap((labMap: LabMap) =>
      this.labMapService.updateLabMap(labMap).pipe(
        map(updatedLabMap => (new labMapActions.UpdateLabMapSuccess(updatedLabMap))),
        catchError(err => of(new labMapActions.UpdateLabMapFail(err)))
      )
    )
  );

  @Effect()
  createLabMap$: Observable<Action> = this.actions$.pipe(
    ofType(labMapActions.LabMapActionTypes.CreateLabMap),
    map((action: labMapActions.CreateLabMap) => action.payload),
    mergeMap((labMap: LabMap) =>
      this.labMapService.createLabMap(labMap).pipe(
        map(newLabMap => (new labMapActions.CreateLabMapSuccess(newLabMap))),
        catchError(err => of(new labMapActions.CreateLabMapFail(err)))
      )
    )
  );

  @Effect()
  deleteLabMap$: Observable<Action> = this.actions$.pipe(
    ofType(labMapActions.LabMapActionTypes.DeleteLabMap),
    map((action: labMapActions.DeleteLabMap) => action.payload),
    mergeMap((labMapId: string) =>
      this.labMapService.deleteLabMap(labMapId).pipe(
        map(() => (new labMapActions.DeleteLabMapSuccess(labMapId))),
        catchError(err => of(new labMapActions.DeleteLabMapFail(err)))
      )
    )
  );
}
