import { NgModule } from '@angular/core';
import { InOutConnectionComponent } from './in-out-connection/in-out-connection.component';
import { SignoutRedirectCallbackComponent } from './signinout-redirect/signout-redirect-callback.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
@NgModule({
	declarations: [
		InOutConnectionComponent,
		SignoutRedirectCallbackComponent,
		UnauthorizedComponent,
	],
	imports: [],
	exports: [
	]
})
export class ComponentsModule { }


