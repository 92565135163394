import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LabAccount } from '../../lab-account';
import { GenericValidator } from '../../../shared/generic-validator';
import { NumberValidators } from '../../../shared/number.validator';

@Component({
  selector: 'lab-account-edit',
  templateUrl: './lab-account-edit.component.html',
  styleUrls: ['./lab-account-edit.component.scss']
})
export class LabAccountEditComponent implements OnInit, OnChanges, OnDestroy {
  pageTitle = 'LabAccount Edit';
  @Input() errorMessage: string;
  @Input() selectedLabAccount: LabAccount;
  @Output() create = new EventEmitter<LabAccount>();
  @Output() update = new EventEmitter<LabAccount>();
  @Output() delete = new EventEmitter<LabAccount>();
  @Output() clearCurrent = new EventEmitter<void>();

  componentActive = true;
  labAccountForm: FormGroup;

  labAccount: LabAccount | null;

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  constructor(private fb: FormBuilder) {
    // Defines all of the validation messages for the form.
    // These could instead be retrieved from a file or database.
    this.validationMessages = {
      // name: {
      //   required: 'LabAccount name is required.',
      //   minlength: 'LabAccount name must be at least three characters.',
      //   maxlength: 'LabAccount name cannot exceed 50 characters.'
      // },
      // mapId: {
      //   required: 'LabAccount code is required.'
      // },
      // starRating: {
      //   range: 'Rate the labAccount between 1 (lowest) and 5 (highest).'
      // }
    };

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    // Define the form group
    this.labAccountForm = this.fb.group({
      userId: { value: '', disabled: true },
      domain: '',
      identity: '',
      displayName: '',
      email: '',
      homeMapId: '',
      settings: '',
      userType: { value: 0, disabled: true },
      level: { value: 0, disabled: true },
      imageURL: '',
    });

    // Watch for value changes
    this.labAccountForm.valueChanges.subscribe(
      value => this.displayMessage = this.genericValidator.processMessages(this.labAccountForm)
    );


    if (this.selectedLabAccount != null) {
      this.displayLabAccount(this.selectedLabAccount);
    }

  }

  ngOnChanges(changes: SimpleChanges): void {

    // patch form with value from the store
    if (changes.selectedLabAccount) {
      const labAccount: any = changes.selectedLabAccount.currentValue as LabAccount;
      this.displayLabAccount(labAccount);
    }
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  // Also validate on blur
  // Helpful if the user tabs through required fields
  blur(): void {
    this.displayMessage = this.genericValidator.processMessages(this.labAccountForm);
  }

  displayLabAccount(labAccount: LabAccount | null): void {
    // Set the local labAccount property
    this.labAccount = labAccount;

    if (this.labAccount && this.labAccountForm) {
      // Reset the form back to pristine
      this.labAccountForm.reset();


      this.pageTitle = `Edit LabAccount`;


      // Update the data on the form
      this.labAccountForm.patchValue({
        userId: this.labAccount.userId,
        domain: this.labAccount.domain,
        identity: this.labAccount.identity,
        displayName: this.labAccount.displayName,
        email: this.labAccount.email,
        homeMapId: this.labAccount.homeMapId,
        settings: JSON.stringify(this.labAccount.settings),
        userType: this.labAccount.userType,
        level: this.labAccount.level,
        imageURL: this.labAccount.imageURL,
      });
    }
  }

  cancelEdit(): void {
    // Redisplay the currently selected labAccount
    // replacing any edits made
    this.displayLabAccount(this.labAccount);
  }

  // deleteLabAccount(): void {
  //   if (this.labAccount && this.labAccount.id) {
  //     if (confirm(`Really delete the labAccount: ${this.labAccount.name}?`)) {
  //       this.delete.emit(this.labAccount);
  //     }
  //   } else {
  //     // No need to delete, it was never saved
  //     this.clearCurrent.emit();
  //   }
  // }

  saveLabAccount(): void {
    if (this.labAccountForm.valid) {
      if (this.labAccountForm.dirty) {

        let labAccountFormValue = { ...this.labAccountForm.value };
        let settingsString = labAccountFormValue["settings"];
        labAccountFormValue["settings"] = JSON.parse(settingsString);
        // Copy over all of the original labAccount properties
        // Then copy over the values from the form
        // This ensures values not on the form, such as the Id, are retained
        const p = { ...this.labAccount, ...labAccountFormValue };
        // const p = { ...this.labAccount, ...this.labAccountForm.value };

        if (p.id === 0) {
          this.create.emit(p);
        } else {
          this.update.emit(p);
        }
      }
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

}
