import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromLabAppSettings from './../../state';
// import * as fromLabAppSettings from '../state';
import * as fromRoot from '../../../state/app.state';

import * as labAppSettingsActions from './../../state/';
import { LabAppSettings } from '../../lab-app-settings';

@Component({
  templateUrl: './lab-app-settings-shell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabAppSettingsShellComponent implements OnInit {
  selectedLabAppSettings$: Observable<LabAppSettings>;
  errorMessage$: Observable<string>;

  constructor(
    private store: Store<fromRoot.State>,
    ) {}

  ngOnInit(): void {
    this.selectedLabAppSettings$ = this.store.pipe(select(fromLabAppSettings.getLabAppSettings));
  }

  updateLabAppSettings(labAppSettings: LabAppSettings): void {
    this.store.dispatch(new labAppSettingsActions.SetLabAppSettings(labAppSettings));
  }
}
