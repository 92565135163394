import { Injectable } from '@angular/core';

import { Observable, of, throwError, from } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { LabAppSettings, LabAppSettingsPersist } from './lab-app-settings';
import { StorageService } from '../services/storage.service';
import { Constants } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class LabAppSettingsService {

  static KEY = "AppSetting";

  currentLabAppSettings: LabAppSettings = null;

 

  constructor(
    private cache: StorageService) { }

  getLabAppSettings(parameter: {}): Observable<LabAppSettings> {

    let params = null;

    let value = this.cache.get(LabAppSettingsService.KEY);

    return this.cache.get(LabAppSettingsService.KEY)
      .pipe(
        tap(data => console.log(`FRANZ: getLabAppSettings: ${JSON.stringify(data)}`)),

        map((data: any) => {
          this.currentLabAppSettings = obj;
          var obj = data == null ? Constants.DEFAULT_LAB_APP_SETTINGS: JSON.parse(data);
          return obj;
        }),

        catchError(this.handleError)
      );
  }


  setLabAppSettings(labAppSettings: LabAppSettings): Observable<LabAppSettings> {

    // only persist some values
    var labAppSettingsPersist: LabAppSettingsPersist = {
      currentMapId: labAppSettings.currentMapId,
      currentBoxId: labAppSettings.currentBoxId,
      isDemo: labAppSettings.isDemo,
      darkMode: labAppSettings.darkMode,
    };

    var persistChanges = this.currentLabAppSettings == null ||
      labAppSettingsPersist.currentMapId != this.currentLabAppSettings.currentMapId ||
      labAppSettingsPersist.currentBoxId != this.currentLabAppSettings.currentBoxId ||
      labAppSettingsPersist.isDemo != this.currentLabAppSettings.isDemo ||
      labAppSettingsPersist.darkMode != this.currentLabAppSettings.darkMode;

    if (!persistChanges) {
      return from([labAppSettings]);
    }

    var value = JSON.stringify(labAppSettingsPersist);

    return this.cache.set<string>(LabAppSettingsService.KEY, value)
      .pipe(
        // tap(data => console.log('setLabAppSettings: ' + JSON.stringify(data))),

        // return not only the persisted values, return all values
        map(() => { return labAppSettings }),
        catchError(this.handleError)
      );
  }

  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else
      if (typeof err === 'string') {
        errorMessage = err;
      }
      else {
        // // The backend returned an unsuccessful response code.
        // // The response body may contain clues as to what went wrong,
        // errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
        errorMessage = `An error occurred ${err} / ${JSON.stringify(err)}`;
      }
    // console.error(err);
    return throwError(err);
    // return throwError(errorMessage);
  }

}
