export class KtPackageAudit {

    public messageGuid: string;
    public timestamp: string;
    public fromBoxId: string;
    public fromOutputId: number;
    public toBoxId: string;
    public toInputId: number;


    constructor(dataObject?: any) {
        if (dataObject != null) {
            if (dataObject.MessageGuid != null) { this.messageGuid = dataObject.MessageGuid; }
            if (dataObject.Timestamp != null) { this.timestamp = dataObject.Timestamp; }
            if (dataObject.FromBoxId != null) { this.fromBoxId = dataObject.FromBoxId; }
            if (dataObject.FromOutputId != null) { this.fromOutputId = dataObject.FromOutputId; }
            if (dataObject.ToBoxId != null) { this.toBoxId = dataObject.ToBoxId; }
            if (dataObject.ToInputId != null) { this.toInputId = dataObject.ToInputId; }
        }
    }

    public getEditObject() {
        let itemTmp = {
            // "Name": this.name,
        }
        return itemTmp;
    }

}

