import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { LabBoxShellComponent } from './containers/lab-box-shell/lab-box-shell.component';
import { LabBoxListComponent } from './components/lab-box-list/lab-box-list.component';
import { LabBoxEditComponent } from './components/lab-box-edit/lab-box-edit.component';

/* NgRx */
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/lab-box.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LabBoxEffects } from './state/lab-box.effects';
import { IonicModule } from '@ionic/angular';
import { LabBoxConnectFunctionModalComponent } from './components/lab-box-connect-function-modal/lab-box-connect-function-modal.component';
import { LabBoxSendMessageModalComponent } from './components/lab-box-send-message-modal/lab-box-send-message-modal.component';
import { TranslateModule } from '@ngx-translate/core';

const labBoxRoutes: Routes = [
  { path: '', component: LabBoxShellComponent }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(labBoxRoutes),
    StoreModule.forFeature('labBoxes', reducer),
    EffectsModule.forFeature(
      [ LabBoxEffects ]
    ),
    TranslateModule.forChild(),
    IonicModule,
  ],
  declarations: [
    LabBoxShellComponent,
    LabBoxListComponent,
    LabBoxEditComponent,
    LabBoxConnectFunctionModalComponent,
    LabBoxSendMessageModalComponent,
  ],
  entryComponents: [
    LabBoxConnectFunctionModalComponent,
    LabBoxSendMessageModalComponent,
  ]
})
export class LabBoxModule { }
