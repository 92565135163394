import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AdminRoutingModule } from './admin-routing.module';
import { IonicModule } from '@ionic/angular';
import { LabAdminComponent } from './lab-admin/lab-admin.component';
import { ServicesModule } from '../services/services.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AdminRoutingModule,
    ServicesModule,
  ],
  exports: [],
  declarations: [
    LabAdminComponent
  ],
  providers: [],
  entryComponents: [
    LabAdminComponent
  ]
})
export class AdminModule { }


