// export interface IAccountInfo{

//      domain: string;
//      identity: string;
//      userId: string;
//      displayName: string;
//      email: string;
//      level: number;
//      homeMapId: string;
//      settings: string;

//      imageURL?: string;

// }

export class KtAccount {

    public domain: string;
    public identity: string;
    public userId: string;
    public displayName: string;
    public email: string;
    public level: number;
    public homeMapId: string;
    public settings: string;

    public imageURL?: string;


    // "Domain": "test",
    // "Identity": "test",
    // "UserId": "c0e1cac0-f8db-47a3-abdf-575ed872916c",
    // "DisplayName": "test2",
    // "Email": "email2",
    // "UserType": 3,
    // "Level": 0,
    // "HomeMapId": "dc9bb248-7576-4121-bb53-48d36d76f479",
    // "Settings": "{\"key2\":\"value2\"}"


    constructor(dataObject?: any) {
        if (dataObject != null) {
            if (dataObject.Domain != null) { this.domain = dataObject.Domain; }
            if (dataObject.Identity != null) { this.identity = dataObject.Identity; }
            if (dataObject.USerId != null) { this.userId = dataObject.UserId; }
            if (dataObject.DisplayName != null) { this.displayName = dataObject.DisplayName; }
            if (dataObject.Email != null) { this.email = dataObject.Email; }
            if (dataObject.Level != null) { this.level = dataObject.Level; }
            if (dataObject.HomeMapId != null) { this.homeMapId = dataObject.HomeMapId; }
            if (dataObject.Settings != null) { this.settings = dataObject.Settings; }
            if (dataObject.ImageURL != null) { this.imageURL = dataObject.ImageURL; }
        }
    }




    public getEditObject() {
        let itemTmp = {
            "DisplayName": this.displayName,
            "Email": this.email,
            "Settings": this.settings,
        }
        return itemTmp;
    }

}
