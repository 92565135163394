import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { LabMap, ApiLabMap } from './lab-map';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class LabMapService {
  private labMapsUrl = 'v1.0/me/maps';
  private serializer = new LabMapSerializer();

  constructor(
    private http: HttpClient,
    private api: ApiService) { }

  getLabMaps(): Observable<LabMap[]> {

    let params = null;
    return this.api.get(this.labMapsUrl, params)
      .pipe(
        // tap(data => console.log(JSON.stringify(data))),
        map((data: any) => this.convertData<LabMap>(data)),
        catchError(this.handleError)
      );
  }

  createLabMap(labMap: LabMap): Observable<LabMap> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    labMap.id = null;
    return this.api.post<LabMap>(this.labMapsUrl, labMap, { headers: headers })
      .pipe(
        // tap(data => console.log('createLabMap: ' + JSON.stringify(data))),
        map(() => labMap),
        catchError(this.handleError)
      );
  }

  deleteLabMap(id: string): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.labMapsUrl}/${id}`;
    return this.api.delete<LabMap>(url, { headers: headers })
      .pipe(
        // tap(data => console.log('deleteLabMap: ' + id)),
        catchError(this.handleError)
      );
  }

  updateLabMap(labMap: LabMap): Observable<LabMap> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.labMapsUrl}/${labMap.id}`;
    return this.api.put<LabMap>(url, labMap, { headers: headers })
      .pipe(
        // tap(() => console.log('updateLabMap: ' + labMap.id)),
        // Return the labMap on an update
        map(() => labMap),
        catchError(this.handleError)
      );
  }


  private convertData<T>(data: any): T[] {
    return data.map((item: any) => this.serializer.fromJson(item));
  }

  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else
      if (typeof err === 'string') {
        errorMessage = err;
      }
      else {
        // // The backend returned an unsuccessful response code.
        // // The response body may contain clues as to what went wrong,
        // errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
        errorMessage = `An error occurred ${err} / ${JSON.stringify(err)}`;
      }
    // console.error(err);
    return throwError(err);
    // return throwError(errorMessage);
  }

}


export class LabMapSerializer {
  fromJson(json: ApiLabMap): LabMap {
    const labMap =
    {
      id: json.Id,
      name: json.Name,
      version: json.Version,
      level: json.Level,
      imageURL: json.ImageURL,
    };


    if (labMap.imageURL == null) {
      labMap.imageURL =
        "./assets/imgs/home_map.png";
      // map.id == this.account.homeMapId
      //   ? "./assets/imgs/home_map.png"
      //   : "./assets/imgs/logo.png";
    }



    return labMap;
  }

  public createLabMapName(id: string) {
    if (id != null && id.length >= 8) {
      return "labMap_" + id.substr(0, 8);
    }
    return id;
  }


  toJson(labMap: LabMap): ApiLabMap {
    return {
      Id: labMap.id,
      Name: labMap.name,
      Version: labMap.version,
      Level: labMap.level,
      ImageURL: labMap.imageURL,
    };
  }
}