import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../state/app.state';
import * as fromLabBoxes from './lab-box.reducer';
import { getLabAppSettings } from '../../lab-app-settings/state';

// Extends the app state to include the box feature.
// This is required because boxes are lazy loaded.
// So the reference to BoxState cannot be added to app.state.ts directly.
export interface State extends fromRoot.State {
    labBoxes: fromLabBoxes.LabBoxState;
}

// Selector functions
const getLabBoxFeatureState = createFeatureSelector<fromLabBoxes.LabBoxState>('labBoxes');

export const getShowLabBoxId = createSelector(
    getLabBoxFeatureState,
    state => state.showLabBoxId
);

export const getCurrentLabBoxId = createSelector(
    getLabBoxFeatureState,
    state => state.currentLabBoxId
);

export const getCurrentLabBox = createSelector(
    getLabBoxFeatureState,
    getCurrentLabBoxId,
    (state, currentLabBoxId) => {
        if (currentLabBoxId === "0") {
            return {
                // id: 0,
                // boxName: '',
                // boxCode: 'New',
                // description: '',
                // starRating: 0
                id: "0",
                name: '',
                mapId: '',
                properties: '',
            };
        } else {
            return currentLabBoxId ? state.labBoxes.find(p => p.id === currentLabBoxId) : null;
        }
    }
);

export const getLabBoxes = createSelector(
    getLabBoxFeatureState,
    state => state.labBoxes
);



export const getLabBoxesForMap = createSelector(
    getLabBoxFeatureState,
    getLabAppSettings,
    (state, labAppSettings) => {
        let currentLabMapId = "0";
        if (labAppSettings!=null){
            currentLabMapId = labAppSettings.currentMapId;
        }
        if (currentLabMapId === "0") {
            return [];
        } else {
            return currentLabMapId ? state.labBoxes.filter(p => p.mapId === currentLabMapId) : null;
        }
    }        
);

export const getError = createSelector(
    getLabBoxFeatureState,
    state => state.error
);
