import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { LabAccountShellComponent } from './containers/lab-account-shell/lab-account-shell.component';
import { LabAccountEditComponent } from './components/lab-account-edit/lab-account-edit.component';

/* NgRx */
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/lab-account.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LabAccountEffects } from './state/lab-account.effects';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

const labAccountRoutes: Routes = [
  { path: '', component: LabAccountShellComponent }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(labAccountRoutes),
    StoreModule.forFeature('labAccount', reducer),
    EffectsModule.forFeature(
      [LabAccountEffects]
    ),
    TranslateModule.forChild(),
    IonicModule,
  ],
  declarations: [
    LabAccountShellComponent,
    LabAccountEditComponent
  ]
})
export class LabAccountModule { }
