import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthServiceOidc } from './auth-service.component';

@Injectable()
export class AdminRouteGuard implements CanActivate {
    constructor(private _authService: AuthServiceOidc) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log(`canActivate: this._authService.authContext: ${!!this._authService.authContext}`);
        return !!this._authService.authContext &&
        this._authService.authContext.isAdmin();
    }
}