import { Injectable } from '@angular/core';
import { KtBox } from '../models/ktbox';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BoxService {


  constructor(
    public api: ApiService
  ) {
    console.log('Hello BoxServiceProvider Provider');
  }


  getBoxes(): Promise<KtBox[]> {

    let url = 'v1.0/me/boxes';

    return new Promise((resolve, reject) => {
      let params = null;
      this.api.get(url, params)
        .subscribe((data: any) => {
          console.log(`FRANZ: data: const boxes = ${JSON.stringify(data)}`);
          var dataObjects = data as Object[];

          // let item: KtAccount = new KtAccount(dataObject);
          // resolve(item);

          // var items = this.mapDataArray(dataObjects) as Item[];

          let items: KtBox[] = [];
          for (let item of dataObjects) {
            items.push(new KtBox(item));
          }
          resolve(items);

        }, (error) => {
          this.handleError(error);
        });
    });

  }


  getBox(boxId: string): Promise<KtBox> {

    return this.getBoxes()
      .then((boxes) => {
        return boxes.find((box) => {
          return box.id == boxId;
        });
      });
  }


  public createBoxName(box: KtBox) {
    box.name = "box_" + box.id.substr(0, 8);
  }

  addBox() {

  }
  editBox(box: KtBox, boxItems: any): Promise<{}> {

    //  http://localhost:7071/api/v1.0/me/maps/dc9bb248-7576-4121-bb53-48d36d76f479/boxes/45981d96-032e-4fd7-ba5e-90bc798810c4


    if (boxItems == null) {
      throw new Error("PackageService::addPackage(): missing boxItems");
    }

    if (box == null) {
      throw new Error("PackageService::addPackage(): missing box");
    }
    let mapId = box.mapId;
    let boxId = box.id;

    if (mapId == null || mapId.length == 0) {
      throw new Error("PackageService::addPackage(): missing mapId");
    }

    if (boxId == null || boxId.length == 0) {
      throw new Error("PackageService::addPackage(): missing boxId");
    }

    let url = `v1.0/me/maps/${mapId}/boxes/${boxId}`;

    return new Promise((resolve, reject) => {
      let params = null;
      let editObject =
        {
          "azFctUrl": boxItems.azFctUrl,
          "secret": boxItems.secret
        };
      let body = JSON.stringify(editObject);

      console.log(`FRANZ: body: ${JSON.stringify(body)}`);

      this.api.post(url, body, params)
        .subscribe((data: any) => {
          console.log(`FRANZ: data: ${JSON.stringify(data)}`);
          resolve({});
        }, (error) => {
          this.handleError(error);
        });
    });

  }



  private handleError(error: any): Promise<any> {
    console.error(`${this.constructor.name}: An error occurred`, error);
    return Promise.reject(error.message || error);
  }



}
