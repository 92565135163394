export class KtMap {

    public name: string;
    public id: string;
    public version: string;
    public level: number;
    public imageURL?: string;


    constructor(dataObject?: any) {
        if (dataObject != null) {
            if (dataObject.Name != null) { this.name = dataObject.Name; }
            if (dataObject.Id != null) { this.id = dataObject.Id; }
            if (dataObject.Version != null) { this.version = dataObject.Version; }
            if (dataObject.Level != null) { this.level = dataObject.Level; }
            if (dataObject.ImageURL != null) { this.imageURL = dataObject.ImageURL; }
        }
    }

    public getEditObject() {
        let itemTmp = {
            "Name": this.name,
            "ImageUrl": this.imageURL,
        }
        return itemTmp;
    }

}
