import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { LabBoxService } from '../lab-box.service';
import { LabBox } from '../lab-box';

/* NgRx */
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as labBoxActions from './lab-box.actions';

@Injectable()
export class LabBoxEffects {

  constructor(private labBoxService: LabBoxService,
              private actions$: Actions) { }

  @Effect()
  loadLabBoxes$: Observable<Action> = this.actions$.pipe(
    ofType(labBoxActions.LabBoxActionTypes.Load),
    mergeMap(action =>
      this.labBoxService.getLabBoxes().pipe(
        map(labBoxes => (new labBoxActions.LoadSuccess(labBoxes))),
        catchError(err => of(new labBoxActions.LoadFail(err)))
      )
    )
  );

  @Effect()
  updateLabBox$: Observable<Action> = this.actions$.pipe(
    ofType(labBoxActions.LabBoxActionTypes.UpdateLabBox),
    map((action: labBoxActions.UpdateLabBox) => action.payload),
    mergeMap((labBox: LabBox) =>
      this.labBoxService.updateLabBox(labBox).pipe(
        map(updatedLabBox => (new labBoxActions.UpdateLabBoxSuccess(updatedLabBox))),
        catchError(err => of(new labBoxActions.UpdateLabBoxFail(err)))
      )
    )
  );


  @Effect()
  updateLabBoxConnectToFunction$: Observable<Action> = this.actions$.pipe(
    ofType(labBoxActions.LabBoxActionTypes.UpdateLabBoxConnectToFunction),
    map((action: labBoxActions.UpdateLabBoxConnectToFunction) => action.payload),
    mergeMap((labBox: LabBox) =>
      this.labBoxService.updateLabBoxConnectToFunction(labBox).pipe(
        map(updatedLabBox => (new labBoxActions.UpdateLabBoxConnectToFunctionSuccess(updatedLabBox))),
        catchError(err => of(new labBoxActions.UpdateLabBoxConnectToFunctionFail(err)))
      )
    )
  );

  

  @Effect()
  sendLabBoxMessage$: Observable<Action> = this.actions$.pipe(
    ofType(labBoxActions.LabBoxActionTypes.SendLabBoxMessage),
    map((action: labBoxActions.SendLabBoxMessage) => action.payload),
    mergeMap((labBox: LabBox) =>
      this.labBoxService.sendLabBoxMessage(labBox).pipe(
        map(updatedLabBox => (new labBoxActions.SendLabBoxMessageSuccess(updatedLabBox))),
        catchError(err => of(new labBoxActions.SendLabBoxMessageFail(err)))
      )
    )
  );


  @Effect()
  createLabBox$: Observable<Action> = this.actions$.pipe(
    ofType(labBoxActions.LabBoxActionTypes.CreateLabBox),
    map((action: labBoxActions.CreateLabBox) => action.payload),
    mergeMap((labBox: LabBox) =>
      this.labBoxService.createLabBox(labBox).pipe(
        map(newLabBox => (new labBoxActions.CreateLabBoxSuccess(newLabBox))),
        catchError(err => of(new labBoxActions.CreateLabBoxFail(err)))
      )
    )
  );

  @Effect()
  deleteLabBox$: Observable<Action> = this.actions$.pipe(
    ofType(labBoxActions.LabBoxActionTypes.DeleteLabBox),
    map((action: labBoxActions.DeleteLabBox) => action.payload),
    mergeMap((labBoxId: string) =>
      this.labBoxService.deleteLabBox(labBoxId).pipe(
        map(() => (new labBoxActions.DeleteLabBoxSuccess(labBoxId))),
        catchError(err => of(new labBoxActions.DeleteLabBoxFail(err)))
      )
    )
  );
}
