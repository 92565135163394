import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { KtPackage } from '../models/ktpackage';
import { KtPackageAudit } from '../models/ktpackageaudit';
import { KtBox } from '../models/ktbox';
import { Item } from '../models/item';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  constructor(
    public api: ApiService
  ) { }


  
  getPackages(mapId: string, boxId: string): Promise<KtPackage[]> {
    //  http://localhost:7071/api/v1.0/me/maps/dc9bb248-7576-4121-bb53-48d36d76f479/boxes/45981d96-032e-4fd7-ba5e-90bc798810c4/packages

    console.log(`FRANZ: getPackages(mapId:${mapId}, boxId:${boxId})`);

    if (mapId == null || mapId.length == 0) {
      throw new Error("PackageService::getPackages(): missing mapId");
    }

    if (boxId == null || boxId.length == 0) {
      throw new Error("PackageService::getPackages(): missing boxId");
    }

    let url = `v1.0/me/maps/${mapId}/boxes/${boxId}/packages`;

    return new Promise((resolve, reject) => {
      let params = null;
      this.api.get(url, params)
        .subscribe((data: any) => {
          console.log(`FRANZ: data: const packages = ${JSON.stringify(data)}`);
          var dataObjects = data as Object[];

          // let item: KtAccount = new KtAccount(dataObject);
          // resolve(item);

          // var items = this.mapDataArray(dataObjects) as Item[];

          let items: KtPackage[] = [];
          for (let item of dataObjects) {
            items.push(new KtPackage(item));
          }
          resolve(items);

        }, (error) => {
          this.handleError(error);
        });
    });
  }


  getPackageAudit(mapId: string, boxId: string, packageId: string): Promise<KtPackageAudit[]> {


    // http://localhost:7071/api/v1.0/me/maps/dc9bb248-7576-4121-bb53-48d36d76f479/boxes/4581e8c6-d464-4563-a9cb-32325e6807a5/packages/7926c4d2-2385-49c0-aa0d-06edb9e27d14/audit

    if (mapId == null || mapId.length == 0) {
      throw new Error("PackageService::getPackageAudit(): missing mapId");
    }

    if (boxId == null || boxId.length == 0) {
      throw new Error("PackageService::getPackageAudit(): missing boxId");
    }
    if (packageId == null || packageId.length == 0) {
      throw new Error("PackageService::getPackageAudit(): missing packageId");
    }

    let url = `v1.0/me/maps/${mapId}/boxes/${boxId}/packages/${packageId}/audit`;

    return new Promise((resolve, reject) => {
      let params = null;
      this.api.get(url, params)
        .subscribe((data: any) => {
          console.log(`FRANZ: data: const audit = ${JSON.stringify(data)}`);
          var dataObjects = data as Object[];

          // let item: KtAccount = new KtAccount(dataObject);
          // resolve(item);

          // var items = this.mapDataArray(dataObjects) as Item[];

          let items: KtPackageAudit[] = [];
          for (let item of dataObjects) {
            items.push(new KtPackageAudit(item));
          }
          resolve(items);

        }, (error) => {
          this.handleError(error);
        });
    });
  }


  getPackage(mapId: string, boxId: string, messageId: string) : Promise<KtPackage>{
    return this.getPackages(mapId, boxId)
      .then((packages) => {
        console.error(`franz: `)
        return packages.find((item: KtPackage) => {
          return item.messageId == messageId;
        });
      });
  }
  routeTo(ktPackage: KtPackage, outputId: number): Promise<{}> {

    // http://localhost:7071/api/v1.0/me/maps/dc9bb248-7576-4121-bb53-48d36d76f479/boxes/4581e8c6-d464-4563-a9cb-32325e6807a5/packages/7926c4d2-2385-49c0-aa0d-06edb9e27d14/route

    if (outputId < 0 || outputId > 3) {
      throw new Error("PackageService::routeTo(): unsupported outputId: " + outputId);
    }

    if (ktPackage == null) {
      throw new Error("PackageService::routeTo(): missing package");
    }
    let mapId = ktPackage.mapId;
    let boxId = ktPackage.boxId;
    let packageId = ktPackage.messageId;

    if (mapId == null || mapId.length == 0) {
      throw new Error("PackageService::routeTo(): missing mapId");
    }

    if (boxId == null || boxId.length == 0) {
      throw new Error("PackageService::routeTo(): missing boxId");
    }
    if (packageId == null || packageId.length == 0) {
      throw new Error("PackageService::routeTo(): missing packageId");
    }

    let url = `v1.0/me/maps/${mapId}/boxes/${boxId}/packages/${packageId}/route`;

    return new Promise((resolve, reject) => {
      let params = null;
      let editObject = { "outputId": outputId };
      let body = JSON.stringify(editObject);

      console.log(`FRANZ: body: ${JSON.stringify(body)}`);

      this.api.post(url, body, params)
        .subscribe((data: any) => {
          console.log(`FRANZ: data: ${JSON.stringify(data)}`);
          resolve({});
        }, (error) => {
          this.handleError(error);
        });
    });

  }
  getContent() {

  }
  addPackage(box: KtBox, packageItems: any): Promise<{}> {

//  http://localhost:7071/api/v1.0/me/maps/dc9bb248-7576-4121-bb53-48d36d76f479/boxes/4581e8c6-d464-4563-a9cb-32325e6807a5/packages


  if (packageItems==null) {
    throw new Error("PackageService::addPackage(): missing packageItems");
  }

  if (box == null) {
    throw new Error("PackageService::addPackage(): missing box");
  }
  let mapId = box.mapId;
  let boxId = box.id;

  if (mapId == null || mapId.length == 0) {
    throw new Error("PackageService::addPackage(): missing mapId");
  }

  if (boxId == null || boxId.length == 0) {
    throw new Error("PackageService::addPackage(): missing boxId");
  }

  let url = `v1.0/me/maps/${mapId}/boxes/${boxId}/packages`;

  return new Promise((resolve, reject) => {
    let params = null;
    let editObject = 
    {
      "outputId": packageItems.outputId,
      "message": packageItems.messageId,
      "routingHint": packageItems.routingHint || "",
      "toMapId": packageItems.toMapId || mapId,
      "toBoxId": packageItems.toBoxId
    };
    let body = JSON.stringify(editObject);

    console.log(`FRANZ: body: ${JSON.stringify(body)}`);

    this.api.put(url, body, params)
      .subscribe((data: any) => {
        console.log(`FRANZ: data: ${JSON.stringify(data)}`);
        resolve({});
      }, (error) => {
        this.handleError(error);
      });
  });

}


  query(params?: any): Promise<Item[]> {
    // return this.api.get('/items', params);
    console.log("TEST");
    let url = '/Api_Package_GetInfo';

    return new Promise((resolve, reject) => {
      this.api.get(url, params)
        .subscribe((data: any) => {
          console.log(`FRANZ: data: ${data}`);
          var dataObjects = data as Object[];
          // var items = this.mapDataArray(dataObjects) as Item[];

          let items: Item[] = [];
          for (let item of dataObjects) {
            items.push(new Item(item));
          }
          resolve(items);
        }, (error) => {
          this.handleError(error);
        });
    });

  }


  private handleError(error: any): Promise<any> {
    console.error(`${this.constructor.name}: An error occurred`, error);
    return Promise.reject(error.message || error);
  }


  add(item: Item) {
  }

  delete(item: Item) {
  }


  defaultItem: any = {
    "name": "Burt Bear",
    "profilePic": "assets/img/speakers/bear.jpg",
    "about": "Burt is a Bear.",
  };

}
