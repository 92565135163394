import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, Platform, ToastController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
//import { MenuController, Platform, Config } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { TranslateService } from '@ngx-translate/core';

import { Store, select } from '@ngrx/store';
import * as fromRoot from './state/app.state';
import * as fromLabAppSettings from './lab-app-settings/state';
import { AuthServiceOidc } from './services/auth-service.component';
import { LabAppSettings } from './lab-app-settings/lab-app-settings';
// import * as labAppSettingsActions from './../../state/';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  isLoggedIn = false;
  appPages = [
    // {
    //   title: 'Schedule',
    //   url: '/app/tabs/schedule',
    //   icon: 'calendar'
    // },
    // {
    //   title: 'Speakers',
    //   url: '/app/tabs/speakers',
    //   icon: 'contacts'
    // },
    // {
    //   title: 'Map',
    //   url: '/app/tabs/map',
    //   icon: 'map'
    // },
    // {
    //   title: 'About',
    //   url: '/app/tabs/about',
    //   icon: 'information-circle'
    // },

    { title: 'LAB-START_TITLE', url: 'lab-start', icon: 'map' },
    { title: 'LAB-MAPS_TITLE', url: 'lab-maps', icon: 'map' },
    { title: 'LAB-BOXES_TITLE', url: 'lab-boxes', icon: 'map' },
    { title: 'LAB-PACKAGES_TITLE', url: 'lab-packages', icon: 'map' },
    { title: 'LAB-AUDITS_TITLE', url: 'lab-audits', icon: 'map' },
    { title: 'LAB-ACCOUNT_TITLE', url: 'lab-account', icon: 'contacts' },
    { title: 'LAB-CREDIT_TITLE', url: 'lab-credit', icon: 'map' },
    // { title: 'LAB-SETTINGS_TITLE', url: 'lab-settings', icon: 'map' },
    { title: 'LAB-APP-SETTINGS_TITLE', url: 'lab-app-settings', icon: 'map' },

    { title: 'BOX_LIST_TITLE', url: 'box-list', icon: 'map' },
    { title: 'CREDIT_TITLE', url: 'credit-info', icon: 'map' },
    { title: 'ACCOUNT_TITLE', url: 'account-info', icon: 'contacts' },
    { title: 'ABOUT_TITLE', url: 'about', icon: 'information-circle' },

    { title: '', component: '' },
    { title: 'WELCOME_TITLE', url: 'welcome', icon: 'map' },
    { title: 'PACKAGE_LIST_TITLE', url: 'package-list', icon: 'map' },
    { title: 'LOGIN_TITLE', url: 'login', icon: 'map' },
    { title: 'SIGNUP_TITLE', url: 'signup', icon: 'map' },
    { title: 'SETTINGS_TITLE', url: 'settings', icon: 'map' },
  ];
  loggedIn = false;
  dark = false;
  labAppSettings: LabAppSettings = null;


  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private store: Store<fromRoot.State>,
    private _authService: AuthServiceOidc,
  ) {
    this.initializeApp();
    this._authService.loginChanged.subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
    })
  }

  async ngOnInit() {
    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        showCloseButton: true,
        position: 'bottom',
        closeButtonText: `Reload`
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }




  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.initTranslate();
    this.loadAppStatus();
    this.initDarkMode();
  }

  checkLoginStatus() {
    // return this.userData.isLoggedIn().then(loggedIn => {
    //   return this.updateLoggedInStatus(loggedIn);
    // });

    this._authService.isLoggedIn().then(loggedIn => {
      this.isLoggedIn = loggedIn;
    })
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }


  initTranslate() {
    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang('en');

    if (this.translate.getBrowserLang() !== undefined) {
      this.translate.use(this.translate.getBrowserLang());
    } else {
      // this.translate.use('en'); // Set your language here
      this.translate.use('de'); // Set your language here
    }

    // TODO FRANZ Upgrade
    // this.translate.get(['BACK_BUTTON_TEXT']).subscribe(values => {
    //   this.config.set('backButtonText', values.BACK_BUTTON_TEXT);
    // });


  }


  
login() {
  this._authService.login();
}

logout() {
    let currentMapId = "";
    let currentBoxId = "";
    var labAppSettings = { ...this.labAppSettings };
    labAppSettings.currentMapId = currentMapId;
    labAppSettings.currentBoxId = currentBoxId;
    this.store.dispatch(new fromLabAppSettings.SetLabAppSettings(labAppSettings));

    this._authService.logout();
}

  // logout() {
  //   this.userData.logout().then(() => {
  //     return this.router.navigateByUrl('/app/tabs/schedule');
  //   });
  // }

  isAdmin() {
    // console.log(`app.components isAdmin: this._authService.authContext: ${!!this._authService.authContext}`);
    
    return this._authService.authContext && this._authService.authContext.isAdmin();
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }


  private loadAppStatus() {

    // // TODO
    // this.store.dispatch(new labAppSettingsActions.LoadLabAppSettings(labAppSettings));

    console.log(`loadAppStatus()`);

    let parameter: {} = {
    }
    this.store.dispatch(new fromLabAppSettings.Load(parameter));

    //   // this.subscriptions.push(
    //     this.store.pipe(
    //     select(fromLabAppSettings.getLabAppSettings)
    //   ).subscribe(labAppSettings => {
    //     this.labAppSettings = labAppSettings;
    //     const force = false;
    //     this.loadPackages(force);
    //   });
    //   // );

  }

  private initDarkMode() {

    // https://ionicframework.com/docs/theming/dark-mode

    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    this.toggleDarkTheme(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));

    // this.subscriptions.push(
    this.store.pipe(
      select(fromLabAppSettings.getLabAppSettings)
    ).subscribe(labAppSettings => {
      this.labAppSettings = labAppSettings;
      console.log(`labAppSettings.darkMode: ${labAppSettings.darkMode}`);
      this.toggleDarkTheme(labAppSettings.darkMode);
    })
    // );
  }

  private toggleDarkTheme(shouldAdd) {
    if (shouldAdd != null) {
      this.dark = shouldAdd;
    }
  }


}
