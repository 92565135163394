import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LabBox } from '../../lab-box';
import { GenericValidator } from '../../../shared/generic-validator';
import { NumberValidators } from '../../../shared/number.validator';
import { ModalController, NavParams } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'lab-box-send-message-modal',
  templateUrl: './lab-box-send-message-modal.component.html',
  styleUrls: ['./lab-box-send-message-modal.component.scss']
})
export class LabBoxSendMessageModalComponent implements OnInit, OnChanges, OnDestroy {

  static readonly ROLE_ADD: string = "add";
  static readonly ROLE_UPDATE: string = "update";

  subscriptions: Subscription[] = [];


  pageTitle = 'LabBox Modal';
  @Input() errorMessage$: Observable<string>;
  @Input() selectedLabBox$: Observable<LabBox>;

  @Input() firstName: string;
  @Input() lastName: string;
  @Input() middleInitial: string;


  componentActive = true;
  labBoxForm: FormGroup;

  labBox: LabBox | null;

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  displayErrorMessage: string = null;

  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private navParams: NavParams,
  ) {
    // Defines all of the validation messages for the form.
    // These could instead be retrieved from a file or database.
    this.validationMessages = {
      toMapId: {
        required: 'toMapId is required.'
      },
      toBoxId: {
        required: 'toBoxId is required.'
      },
      outputId: {
        required: 'outputId is required.'
      },
      routingHint: {
        required: 'routingHint is required.'
      },
      message: {
        required: 'message is required.'
      },
    };

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    // Define the form group
    this.labBoxForm = this.fb.group({
      id: { value: '', disabled: true },
      mapId: { value: '', disabled: true },
      boxId: { value: '', disabled: true },

      toMapId: ['', Validators.required],
      toBoxId: ['', Validators.required],
      outputId: [0, Validators.required],

      routingHint: ['', Validators.required],
      message: ['', Validators.required],

    });

    // Watch for value changes
    this.labBoxForm.valueChanges.subscribe(
      value => this.displayMessage = this.genericValidator.processMessages(this.labBoxForm)
    );


    if (this.selectedLabBox$) {
      this.subscriptions.push(
        this.selectedLabBox$.subscribe(
          labBox => {
            this.displayLabBox(labBox);
          }
        )
      );
    }

    if (this.errorMessage$) {
      this.subscriptions.push(
        this.errorMessage$.subscribe(
          errorMessage => {
            this.displayErrorMessage = errorMessage;
          }
        )
      );
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
  }





  ngOnDestroy(): void {
    this.componentActive = false;
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  // Also validate on blur
  // Helpful if the user tabs through required fields
  blur(): void {
    this.displayMessage = this.genericValidator.processMessages(this.labBoxForm);
  }

  displayLabBox(labBox: LabBox | null): void {
    // Set the local labBox property
    this.labBox = labBox;

    if (this.labBox && this.labBoxForm) {
      // Reset the form back to pristine
      this.labBoxForm.reset();


      this.pageTitle = 'Send Message';


      // Update the data on the form
      this.labBoxForm.patchValue({
        id: this.labBox.id,
        mapId: this.labBox.mapId,
        boxId: this.labBox.id,
        toMapId: '',
        toBoxId: '',
        outputId: 0,

        routingHint: '',
        message: null,
      });
    }
  }


  cancelModal(): void {
    this.dismiss(null);
  }

  saveLabBox(): void {
    if (this.labBoxForm.valid) {
      if (this.labBoxForm.dirty) {
        // Copy over all of the original labBox properties
        // Then copy over the values from the form
        // This ensures values not on the form, such as the Id, are retained
        const p = { ...this.labBox, ...this.labBoxForm.value };
        this.dismiss(p);
      }
    } else {
      this.displayErrorMessage = 'Please correct the validation errors.';
    }
  }


  async dismiss(labBox: LabBox | null) {
    // const result: Date = new Date();
    const data: LabBox | null = labBox;
    let role = "";

    if (labBox !== null) {
      if (labBox.id === '0') {
        role = LabBoxSendMessageModalComponent.ROLE_ADD;
      } else {
        role = LabBoxSendMessageModalComponent.ROLE_UPDATE;
      }
    }
    await this.modalController.dismiss(data, role);
  }

}
