import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../state/app.state';
import * as fromLabMaps from './lab-map.reducer';

// Extends the app state to include the labMap feature.
// This is required because labMaps are lazy loaded.
// So the reference to LabMapState cannot be added to app.state.ts directly.
export interface State extends fromRoot.State {
    labMaps: fromLabMaps.LabMapState;
}

// Selector functions
const getLabMapFeatureState = createFeatureSelector<fromLabMaps.LabMapState>('labMaps');

export const getShowLabMapId = createSelector(
    getLabMapFeatureState,
    state => state.showLabMapId
);

export const getCurrentLabMapId = createSelector(
    getLabMapFeatureState,
    state => state.currentLabMapId
);

export const getCurrentLabMap = createSelector(
    getLabMapFeatureState,
    getCurrentLabMapId,
    (state, currentLabMapId) => {
        if (currentLabMapId === "0") {
            return {
                id: "0",
                name: '',
                version: 1,
                level: 1,
                imageURL: '',
            };
        } else {
            return currentLabMapId ? state.labMaps.find(p => p.id === currentLabMapId) : null;
        }
    }
);

export const getLabMaps = createSelector(
    getLabMapFeatureState,
    state => state.labMaps
);

export const getError = createSelector(
    getLabMapFeatureState,
    state => state.error
);
