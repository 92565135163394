import { LabAccount } from '../lab-account';

/* NgRx */
import { Action } from '@ngrx/store';

export enum LabAccountActionTypes {
  ToggleLabAccountCode = '[LabAccount] Toggle LabAccount Code',
  SetCurrentLabAccount = '[LabAccount] Set Current LabAccount',
  // ClearCurrentLabAccount = '[LabAccount] Clear Current LabAccount',
  InitializeCurrentLabAccount = '[LabAccount] Initialize Current LabAccount',
  Load = '[LabAccount] Load',
  LoadSuccess = '[LabAccount] Load Success',
  LoadFail = '[LabAccount] Load Fail',
  UpdateLabAccount = '[LabAccount] Update LabAccount',
  UpdateLabAccountSuccess = '[LabAccount] Update LabAccount Success',
  UpdateLabAccountFail = '[LabAccount] Update LabAccount Fail',
  CreateLabAccount = '[LabAccount] Create LabAccount',
  CreateLabAccountSuccess = '[LabAccount] Create LabAccount Success',
  CreateLabAccountFail = '[LabAccount] Create LabAccount Fail',
  // DeleteLabAccount = '[LabAccount] Delete LabAccount',
  // DeleteLabAccountSuccess = '[LabAccount] Delete LabAccount Success',
  // DeleteLabAccountFail = '[LabAccount] Delete LabAccount Fail'
}

// Action Creators
export class ToggleLabAccountCode implements Action {
  readonly type = LabAccountActionTypes.ToggleLabAccountCode;

  constructor(public payload: boolean) { }
}

// export class SetCurrentLabAccount implements Action {
//   readonly type = LabAccountActionTypes.SetCurrentLabAccount;

//   constructor(public payload: LabAccount) { }
// }

// export class ClearCurrentLabAccount implements Action {
//   readonly type = LabAccountActionTypes.ClearCurrentLabAccount;
// }

export class InitializeCurrentLabAccount implements Action {
  readonly type = LabAccountActionTypes.InitializeCurrentLabAccount;
}

export class Load implements Action {
  readonly type = LabAccountActionTypes.Load;
}

export class LoadSuccess implements Action {
  readonly type = LabAccountActionTypes.LoadSuccess;

  constructor(public payload: LabAccount) { }
}

export class LoadFail implements Action {
  readonly type = LabAccountActionTypes.LoadFail;

  constructor(public payload: string) { }
}

export class UpdateLabAccount implements Action {
  readonly type = LabAccountActionTypes.UpdateLabAccount;

  constructor(public payload: LabAccount) { }
}

export class UpdateLabAccountSuccess implements Action {
  readonly type = LabAccountActionTypes.UpdateLabAccountSuccess;

  constructor(public payload: LabAccount) { }
}

export class UpdateLabAccountFail implements Action {
  readonly type = LabAccountActionTypes.UpdateLabAccountFail;

  constructor(public payload: string) { }
}

export class CreateLabAccount implements Action {
  readonly type = LabAccountActionTypes.CreateLabAccount;

  constructor(public payload: LabAccount) { }
}

export class CreateLabAccountSuccess implements Action {
  readonly type = LabAccountActionTypes.CreateLabAccountSuccess;

  constructor(public payload: LabAccount) { }
}

export class CreateLabAccountFail implements Action {
  readonly type = LabAccountActionTypes.CreateLabAccountFail;

  constructor(public payload: string) { }
}

// export class DeleteLabAccount implements Action {
//   readonly type = LabAccountActionTypes.DeleteLabAccount;

//   constructor(public payload: string) { }
// }

// export class DeleteLabAccountSuccess implements Action {
//   readonly type = LabAccountActionTypes.DeleteLabAccountSuccess;

//   constructor(public payload: string) { }
// }

// export class DeleteLabAccountFail implements Action {
//   readonly type = LabAccountActionTypes.DeleteLabAccountFail;

//   constructor(public payload: string) { }
// }

// Union the valid types
export type LabAccountActions = ToggleLabAccountCode
  // | SetCurrentLabAccount
  // | ClearCurrentLabAccount
  | InitializeCurrentLabAccount
  | Load
  | LoadSuccess
  | LoadFail
  | UpdateLabAccount
  | UpdateLabAccountSuccess
  | UpdateLabAccountFail
  | CreateLabAccount
  | CreateLabAccountSuccess
  | CreateLabAccountFail
  // | DeleteLabAccount
  // | DeleteLabAccountSuccess
  // | DeleteLabAccountFail
  ;

