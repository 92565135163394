import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromLabAccount from './../../state';
import * as labAccountActions from './../../state/lab-account.actions';
import { LabAccount } from '../../lab-account';

import * as fromLabAppSettings from '../../../lab-app-settings/state';
import { LabAppSettings } from '../../../lab-app-settings/lab-app-settings';
import { Router } from '@angular/router';

@Component({
  templateUrl: './lab-account-shell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabAccountShellComponent implements OnInit, OnDestroy {
  // displayId$: Observable<boolean>;
  selectedLabAccount$: Observable<LabAccount>;
  errorMessage$: Observable<string>;

  labAppSettings: LabAppSettings = null;
  subscriptions: Subscription[] = [];

  
  loading: boolean = false;
  private _refresher: any = null;


  constructor(
    private router: Router,

    private store: Store<fromLabAccount.State>,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new labAccountActions.Load());
    this.selectedLabAccount$ = this.store.pipe(select(fromLabAccount.getLabAccount));
    this.errorMessage$ = this.store.pipe(select(fromLabAccount.getError));


    this.subscriptions.push(
      this.selectedLabAccount$.subscribe(() => {
        this.setLoading(false);
      })
    );

    this.subscriptions.push(this.store.pipe(
      select(fromLabAppSettings.getLabAppSettings)
    ).subscribe(labAppSettings => {
      this.setLoading(false);
      this.labAppSettings = labAppSettings;
    }));


    this.subscriptions.push(
      this.errorMessage$.subscribe((error) => {
        this.setLoading(false);
      })
    );

  }

  ionViewWillEnter() {
    const force = false;
    this.loadAccount(force);
  }

  // Iterate and unsubscribe from each subscription
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe()
    );
  }


  checkChanged(value: boolean): void {
    this.store.dispatch(new labAccountActions.ToggleLabAccountCode(value));
  }

 
  updateLabAccount(labAccount: LabAccount): void {
    this.store.dispatch(new labAccountActions.UpdateLabAccount(labAccount));
  }


  
  refreshData() {
    const force = true;
    this.loadAccount(force);
  }

  doRefresh(event) {
    this._refresher = event.target;
    this.refreshData();
  }


  navigateTo(url: string) {
    this.router.navigate([url]);
  }




  


  loadAccount(force: boolean) {


    this.setLoading(true);

    this.store.dispatch(new labAccountActions.Load());

  }

  private setLoading(loading: boolean) {
    let viewUpdate = this.loading != loading;
    this.loading = loading;

    if (loading == false) {
      // set the refresher to completed
      if (this._refresher != null) {
        this._refresher.complete();
        this._refresher = null;
        viewUpdate = true;
      }
    }

    if (viewUpdate) {
      this.changeDetectorRef.markForCheck();
    }

  }


}
